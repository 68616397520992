import React, { useState } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  CircularProgress
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/styles';
import { loginWithEmailAndPassword } from 'redux/actions/LoginActions';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: '#142669',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%'
    }
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: 0,
  }
}));

const Signin = (props) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .min(4, 'Email must be at least 4 characters'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
  });
  const {
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema)
  })
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  }

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  }

  const onSubmit = async (data) => {
    await props.loginWithEmailAndPassword({email: data.email, password: data.password, history});
  }

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <Container
          className={classes.cardContainer}
          maxWidth="sm"
        >
          <Card>
            <CardContent className={classes.cardContent}>
              <Box
                alignItems="center"
                justifyContent="space-between"
                mb={3}
              >
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h3"
                      style={{textAlign: 'center'}}
                    >
                      Sign in
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Email"
                      value={email}
                      onChange={handleChangeEmail}
                      {...register('email')}
                      style={{width: '100%'}}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      required
                      id="outlined-password-input"
                      label="Password"
                      type="password"
                      value={password}
                      onChange={handleChangePassword}
                      {...register('password')}
                      autoComplete="current-password"
                      style={{width: '100%'}}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {props.login.error && <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="p" style={{color: 'red'}}>Username or Password is incorrect.</Typography>
                  </Grid>}
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{position: 'relative'}}>
                    <Button
                      color="primary"
                      variant="contained"
                      style={{width: '100%'}}
                      onClick={handleSubmit(onSubmit)}
                      disabled={props.login.loading}
                    >
                    Sign in
                    </Button>
                    {props.login.loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
              {/* <Box my={3}>
                <Divider />
              </Box> */}
              {/* <Link
                component={RouterLink}
                to="/register"
                variant="body2"
                color="textSecondary"
              >
                Create new account
              </Link> */}
            </CardContent>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login
});

export default connect(mapStateToProps, { loginWithEmailAndPassword })(Signin);
