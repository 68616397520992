const amsUrl = `https://${process.env.REACT_APP_BUCKET_NAME}.s3.amazonaws.com/images`;
const imageKitUrl = `https://ik.imagekit.io/${process.env.REACT_APP_IMAGEKIT_ID}`;

export const htmlToText = (html) => {
  return html.replaceAll(/<[^>]*>/g, '');
}

export const ellipsis = (origin, maxLength) => {
  return (origin?.length > maxLength) ? origin.substring(0, maxLength) + "..." : origin;
}

export const convertImgUrl = (url, width) => {
  if (url?.includes(amsUrl))
    return url.replace(amsUrl, imageKitUrl).replaceAll('+', '%20') + `/tr:w-${width}`;
  else
    return url;
}

export const convertQuery = (params) => {
  if(params) {
    const keys = Object.keys(params);
    const query = keys
      .filter(key => !!(params[key]))
      .map(key => `${key}=${params[key]}`).join('&');
    return `?${query}`;
  }
  return "";
}