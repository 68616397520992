import { convertQuery } from "utils/string";
import { BASE_API_URL, getHeader, getInstance } from "./basicService";

export const getCodes = async (query) => {
  let res = null;
  await getInstance()
    .get(`${BASE_API_URL}/api/admin/referralcode${convertQuery(query)}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Get code error:", err);
    })
  return res;
}

export const getCodeDetail = async (code) => {
  let res = [];
  await getInstance()
    .get(`${BASE_API_URL}/api/admin/referralcode/${code}/details`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Get code detail error:", err);
    })
  return res;
}

export const getCapturedEmails = async (query) => {
  let res = [];
  await getInstance()
    .get(`${BASE_API_URL}/api/Breeder/capture-email${convertQuery(query)}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Get captured emails error:", err);
    })
  return res;
}

export const activateCode = async (id, code, value) => {
  let res = null;
  await getInstance()
    .put(`${BASE_API_URL}/api/admin/referralcode`, {
      Id: id,
      ReferralCode: code,
      Status: value
    }, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Code activate error:", err);
    });
  return res;
}

export const addCode = async (code, value) => {
  let res = null;
  await getInstance()
    .post(`${BASE_API_URL}/api/admin/referralcode`, {
      ReferralCode: code,
      Status: value
    }, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Code add error:", err);
    });
  return res;
}

export const codeSendWithId = async (id) => {
  let res = null;
  await getInstance()
    .put(`${BASE_API_URL}/api/Breeder/capture-email`, {
      Id: id,
    }, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Code send error:", err);
    });
  return res;
}

export const codeSendWithEmail = async (email, code) => {
  let res = null;
  await getInstance()
    .post(`${BASE_API_URL}/api/Breeder/capture-email`, {
      EmailAddress: email,
      ReferralCode: code
    }, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Email capture error:", err);
    });
  return res;
}

export const deleteCode = async (id) => {
  let res = null;
  await getInstance()
    .delete(`${BASE_API_URL}/api/admin/referralcode/${id}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Code delete:", err);
    });
  return res;
}