import axios from "axios";

let headerOld = null;
let axiosInstance = null;
let headerData = {
	headers: {
		Authorization: `bearer ${localStorage.getItem("jwt_token")}`,
	},
}

const init = (token) => {
	headerOld = {
		headers: {
			Authorization: `bearer ${token}`,
		},
	}
}

const getHeader = () => {
	return headerOld || headerData;
}

const getInstance = () => {
	if (axiosInstance) {
		return axiosInstance;
	} else {
		axiosInstance = axios.create();
		axiosInstance.interceptors.response.use(
			res => res,
			err => {
				// if (err.response.status === 401) {
				// 	localStorage.removeItem("jwt_token");
				// 	window.location.href = '/';
				// }
				throw err;
			}
		)
		return axiosInstance;
	}
}

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

export {
	getInstance,
	getHeader,
	init,
  BASE_API_URL
}