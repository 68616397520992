import { convertQuery } from "utils/string";
import { getHeader, getInstance } from "./basicService";

export const getGroups = async function (status, query) {
  let res = null;
  await getInstance()
    .get(`${process.env.REACT_APP_API_BASE_URL}/api/admin/group/status/${status}${convertQuery(query)}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Groups get error:", err);
    })
  return res;
}

export const updateGroup = async function (body) {
  let res = null;
  await getInstance()
    .put(`${process.env.REACT_APP_API_BASE_URL}/api/admin/group`, body, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Group update error:", err);
    })
  return res;
}

export const updateGroupStatus = async function (id, status) {
  let res = null;
  await getInstance()
    .put(`${process.env.REACT_APP_API_BASE_URL}/api/admin/group/status`, {
      Id: id,
      Status: status
    }, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Group status update error:", err);
    })
  return res;
}

export const pinGroup = async function (id) {
  let res = null;
  await getInstance()
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/admin/group/${id}/pin`, {}, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Group pin error:", err);
    })
  return res;
}

export const showHomeGroup = async function (id) {
  let res = null;
  await getInstance()
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/admin/group/${id}/show-on-home`, {}, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Group set home error:", err);
    })
  return res;
}