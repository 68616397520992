import React, { useEffect, useRef, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Snackbar,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
  MenuItem,
  Select,
  Icon
} from "@material-ui/core";
import {
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_ACTIVE,
  STATUS_NAMES,
  tableHeaders,
} from "../config/constant";
import TabPanel from "../components/TabPanel";
import InputBase from "@material-ui/core/InputBase";
import { styled } from "@material-ui/styles";
import { GridField } from "../components/GridField";
import { useStyles } from "../theme/style";
import AWSUploadFile from "components/FileUploadService";
import { ApproveGroupEmail } from "services/emailService";
import KitImg from "components/kitImg";
import TableHeader from "components/TableHeader";
import Alert from "components/Alert";
import { getGroups, pinGroup, showHomeGroup, updateGroup, updateGroupStatus } from "services/groupService";
import { a11yProps } from "utils/mui";
import Paging from "components/Paging";
import { LoadingButton } from "@material-ui/lab";
import { downloadExcel } from "utils/download";
import { Close, Description, Done, Edit, Home } from "@material-ui/icons";
import PinIcon from "../assets/pin.png";
import UnPinIcon from "../assets/unpin.png";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#AFB3B6",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 4),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    backgroundColor: "#F5F9FC",
    [theme.breakpoints.up("md")]: {
      width: "25ch",
    },
  },
}));

export default function ManageCommunityGroups() {
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [curStatus, setCurStatus] = useState(STATUS_ACTIVE);
  const [uploading, setUploading] = useState(false);
  const [curImage, setCurImage] = useState('');
  const fileInput = useRef(null);
  const [selIndex, setSelIndex] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [inputData, setInputData] = useState({
    Id: 0,
    Name: '',
    Description: '',
    Contents: '',
    Photo: '',
  });

  useEffect(() => {
    setCurPage(1);
    (async function () {
      setIsLoading(true);
      const res = await getGroups(curStatus, {
        skip: curPage - 1,
        take: pageSize
      })
      if (res && res.data?.length) {
        setGroups(res.data);
        setTotal(res.total);
      } else {
        setGroups([]);
        setTotal(0);
      }
      setIsLoading(false);
    })(); // eslint-disable-next-line
  }, [curStatus]);

  const handleDownload = async () => {
    setDownloading(true);
    const res = await getGroups(curStatus, {});
    if (res && res.total > 0) {
      downloadExcel(`${STATUS_NAMES[curStatus]} Groups`, res.data);
    }
    setDownloading(false);
  }

  const handleCloseDetailsDialog = () => {
    setDialogOpen(false);
  };

  const handleClickDetails = (index) => {
    setSelectedGroup(groups[index]);
    setDialogOpen(true);
  };

  const handleClickAction = async (index, action) => {
    const group = groups[index];
    setIsLoading(true);
    const res = await updateGroupStatus(group?.Id, action);
    if (res) {
      if (action === STATUS_ACTIVE) {
        await ApproveGroupEmail(group.CreatedBy.Email, group.CreatedBy.Name, group.Name, group.Id, group.Description, group.Photo);
        setMessage("Group approved successfully!");
      }
      if (action === STATUS_REJECTED) {
        setMessage("Group rejected successfully!");
      }
      setGroups(origin => origin.map((elem, idx) => (idx === index) ? { ...elem, Status: action } : elem));
    }
    setIsLoading(false);
  };

  const handleClickPin = async (index) => {
    setIsLoading(true);
    const res = await pinGroup(groups[index]?.Id);
    if (res) {
      setGroups(origin => origin.map((elem, idx) => (idx === index) ? { ...elem, Pin: !elem.Pin } : elem));
    }
    setIsLoading(false);
  }

  const handleClickHome = async (index) => {
    const res = await showHomeGroup(groups[index]?.Id);
    if (res) {
      setGroups(origin => origin.map((elem, idx) => (idx === index) ? { ...elem, ShowOnHome: !elem.ShowOnHome } : elem));
    }
    setIsLoading(false);
  }

  const handleClickEdit = (index) => {
    setSelectedGroup(groups[index]);
    setSelIndex(index);
    setCurImage(groups[index].Photo);
    setInputData({
      Id: groups[index].Id,
      Name: groups[index].Name,
      Description: groups[index].Description,
      Contents: groups[index].Contents,
      Photo: groups[index].Photo,
    });
    setAddDialogOpen(true);
  }

  const handleClickEditAction = async () => {
    setIsLoading(true);
    const res = await updateGroup(
      {
        Id: inputData.Id,
        Name: inputData.Name,
        Description: inputData.Description,
        Contents: inputData.Contents,
        Photo: inputData.Photo,
      }
    );
    if (res) {
      setGroups(origin => origin.map((elem, idx) => (idx === selIndex) ? { ...elem, ...inputData } : elem));
      setAddDialogOpen(false);
      setMessage("Group saved successfully!");
    }
    setIsLoading(false);
  }

  const handleClickBrowse = (e) => {
    const file = e.currentTarget.files[0];
    setUploading(true);
    getFileURL(file);
  };

  const getFileURL = async (image) => {
    const res = await AWSUploadFile(image, `images/profiles`);
    if (res.location) {
      setUploading(false);
      setCurImage(res.location);
      setNewGroupData('Photo', res.location);
    }
  };

  const setNewGroupData = (key, value) => {
    let tmpInput = {
      ...inputData,
      [key]: value,
    };
    setInputData(tmpInput);
  }

  const handleAlertClose = () => {
  };

  const onRowsPerPageChange = (newSize) => {
    setCurPage(1);
    setPageSize(newSize);
    (async function () {
      setIsLoading(true);
      const res = await getGroups(curStatus, { skip: 0, take: newSize });
      if (res) {
        setGroups(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const onPageChange = (page) => {
    setCurPage(page);
    (async function () {
      setIsLoading(true);
      const res = await getGroups(curStatus, { skip: (page - 1) * pageSize, take: pageSize });
      if (res) {
        setGroups(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const handleSelectChange = async (index, categoryId) => {
    setIsLoading(true);
    const res = await updateGroup(
      {
        ...groups[index],
        CategoryId: categoryId
      }
    );
    if (res) {
      setGroups(origin => origin.map((elem, idx) => (idx === index) ? { ...elem, ...res } : elem));
    }
    setIsLoading(false);
  }

  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader fields={tableHeaders.community.fields} />
          <TableBody>
            {groups?.length > 0 && groups.map(
              (group, index) =>
                group.Status === curStatus && (
                  <TableRow
                    key={group.Id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      className={classes.tableBody}
                      component="th"
                      scope="row"
                    >
                      {index + 1 + (curPage - 1) * pageSize}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      <Box sx={{ display: "flex", alignItems: "center", gap: '10px' }}>
                        <KitImg
                          src={group?.Photo ? group?.Photo : "/assets/user.png"}
                          alt="group"
                          kitwidth={100}
                          className={classes.rounded}
                        />
                        <span>{group?.Name}</span>
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {group?.Description}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {group?.Contents}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {group?.Status
                        ? STATUS_NAMES[group?.Status]
                        : STATUS_NAMES[STATUS_PENDING]}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      <Select
                        size="small"
                        autoWidth
                        inputProps={{ 'aria-label': 'Without label' }}
                        defaultValue={group?.CategoryId}
                        onChange={(e) => handleSelectChange(index, e.target.value)}
                      >
                        <MenuItem value={1}>Program</MenuItem>
                        <MenuItem value={2}>Location</MenuItem>
                        <MenuItem value={3}>Breeds</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Detail">
                        <IconButton
                          color="primary"
                          onClick={() => handleClickDetails(index)}
                        >
                          <Description />
                        </IconButton>
                      </Tooltip>
                      {group?.Status === STATUS_ACTIVE &&
                        <>
                          <Tooltip title={group.Pin ? "Unpin" : "Pin"}>
                            <IconButton
                              onClick={() => handleClickPin(index)}
                            >
                              <Icon><img src={group.Pin ? PinIcon : UnPinIcon} alt="" /></Icon>
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={group.ShowOnHome ? "Hide on home" : "Show on home"}>
                            <IconButton
                              onClick={() => handleClickHome(index)}
                              color={group.ShowOnHome ? "success" : undefined}
                            >
                              <Home />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                      {group?.Status !== STATUS_REJECTED &&
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => handleClickEdit(index)}
                            color="warning"
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      }
                      {curStatus !== STATUS_ACTIVE &&
                        <Tooltip title="Approve">
                          <IconButton
                            onClick={() => handleClickAction(index, STATUS_ACTIVE)}
                            color="success"
                          >
                            <Done />
                          </IconButton>
                        </Tooltip>
                      }
                      {curStatus !== STATUS_REJECTED &&
                        <Tooltip title="Reject">
                          <IconButton
                            onClick={() => handleClickAction(index, STATUS_REJECTED)}
                            color="error"
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                      }
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>

        <Snackbar
          open={false}
          autoHideDuration={6000}
          onClose={handleAlertClose}
        >
          <Alert
            onClose={handleAlertClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </TableContainer >
    );
  };

  return (
    <>
      <LoadingOverlay active={isLoading} spinner={true} text="Loading...">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <LoadingButton
            loading={downloading}
            onClick={handleDownload}
            variant="contained"
            color="success"
          >Download</LoadingButton>
          <Grid item>Total: {total}</Grid>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(e, v) => setValue(v)}
            aria-label="basic tabs example"
          >
            <Tab label="Active groups" {...a11yProps(0)} onClick={() => setCurStatus(STATUS_ACTIVE)} />
            <Tab label="Pending groups" {...a11yProps(1)} onClick={() => setCurStatus(STATUS_PENDING)} />
            <Tab label="Rejected groups" {...a11yProps(2)} onClick={() => setCurStatus(STATUS_REJECTED)} />
          </Tabs>
        </Box>
        <TabPanel>
          {renderTable(STATUS_ACTIVE)}
          <Paging
            onPageSizeChange={onRowsPerPageChange}
            onPageChange={onPageChange}
            total={total}
            curPage={curPage}
          />
        </TabPanel>
        <Dialog
          onClose={handleCloseDetailsDialog}
          aria-labelledby="customized-dialog-title"
          open={dialogOpen}
          classes={{
            paper: classes.dialog,
          }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDetailsDialog}
          >
            Modal title
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <GridField title="Name" col={12}>{selectedGroup?.Name}</GridField>
            </Grid>
            <Grid container spacing={3}>
              <GridField title="Description" col={12}>{selectedGroup?.Description}</GridField>
            </Grid>
            <Grid container spacing={3}>
              <GridField title="Contents" col={12}>{selectedGroup?.Contents}</GridField>
            </Grid>
            {selectedGroup?.Id && (<>
              <Grid container spacing={3}>
                <GridField title="CreatedBy" col={12}>{selectedGroup?.CreatedBy.Name}</GridField>
              </Grid>
              {selectedGroup?.CreatedBy.BreederTags.BreederId && (<>
                <Grid container spacing={3}>
                  <GridField title="Breeder Tags ID" col={12}>{selectedGroup?.CreatedBy.BreederTags.BreederId}</GridField>
                </Grid>
                <Grid container spacing={3}>
                  <GridField title="Breeder Tags Name" col={12}>{selectedGroup?.CreatedBy.BreederTags.Name}</GridField>
                </Grid>
              </>)}
            </>)}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleCloseDetailsDialog}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          onClose={handleCloseDetailsDialog}
          aria-labelledby="customized-dialog-title"
          open={addDialogOpen}
          classes={{
            paper: classes.dialog,
          }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDetailsDialog}
          >
            Edit Community Group
          </DialogTitle>
          <DialogContent dividers>
            <Grid container>
              <GridField col={12} title="Name">
                <StyledInputBase
                  value={inputData.Name}
                  placeholder="Name"
                  onChange={({ target: { value } }) => {
                    setNewGroupData('Name', value);
                  }}
                />
              </GridField>
            </Grid>
            <Grid >
              <GridField title="Description" col={12}>
                <StyledInputBase
                  value={inputData.Description}
                  placeholder="Description"
                  onChange={({ target: { value } }) => {
                    setNewGroupData('Description', value);
                  }}
                />
              </GridField>
            </Grid>
            <Grid container spacing={3}>
              <GridField title="Contents" col={12}>{selectedGroup?.Contents}</GridField>
            </Grid>
            <Grid container>
              <GridField col={12} title="Photo">
                <input
                  type="file"
                  id="imageFile"
                  ref={fileInput}
                  style={{ display: 'none' }}
                  onChange={handleClickBrowse}
                  onKeyUp={handleClickBrowse}
                  accept="image/*"
                />
                <Button color="warning" onClick={() => fileInput.current.click()}>Edit Photo</Button>
                {uploading ? (<img src="/assets/loading.gif" width="30px" alt="uploading" />) : null}
              </GridField>
            </Grid>
            {curImage && (
              <Grid container>
                <KitImg src={curImage} kitwidth={200} width="50px" alt="community" className={classes.rounded} />
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => handleClickEditAction()}
              color="primary"
              disabled={uploading}
              className={classes.sendBtn}
            >
              Save Changes
            </Button>
            <Button
              onClick={() => setAddDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </LoadingOverlay>
    </>
  );
}