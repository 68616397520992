import excel from 'exceljs';
import { saveAs } from 'file-saver';
export const downloadExcel = (title, data) => {
  const workbook = new excel.Workbook();
  const worksheet = workbook.addWorksheet(title);
  worksheet.columns = Object.keys(data[0]).map(fieldName => {
    return {
      header: fieldName,
      key: fieldName,
    }
  })
  let converted = data.map(datum => {
    let element = {};
    Object.keys(datum).forEach(field => {
      element[field] = (typeof (datum[field]) === 'object') ? '' : `${String(datum[field] || "")}`;
    })
    return element;
  })
  worksheet.addRows(converted);
  workbook.xlsx.writeBuffer().then(function (data) {
    let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `${title}.xlsx`);
  })
}