import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    card: {
        background: '#FFFFFF',
        boxShadow: '0px 14px 50px rgba(20, 38, 105, 0.03) !important',
        cursor:'pointer',
        padding:'10px 15px',
        borderRadius:'15px !important',
        height:'100%',
        [theme.breakpoints.up('xl')]: {
            padding: '20px 23px !important',
            borderRadius: '28px !important',
        },
        '&:hover':{
            boxShadow:'none !important',
            transition:'.30s linear'
        }
    },
    cardContent:{
        padding:'1rem 0 !important '
    }
}));

export default function ImageCard({ handelClick, imageSrc, descText }) {

    const classes = useStyles();

    return (
        <Card className={classes.card} onClick={handelClick}>
            <CardMedia
                component="img"
                height="auto"
                image={imageSrc}
                alt={imageSrc}
                sx={{borderRadius:'15px',}}
            />
            <CardContent className={classes.cardContent}>
                <Typography variant="h6" sx={{color:'#070D23'}} textAlign={'left'}>
                    {descText}
                </Typography>
            </CardContent>
        </Card>
    );
}
