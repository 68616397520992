import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  tableHead: {
    color: "#9192A2 !important",
    lineHeight: "28px !important",
    fontWeight: "normal !important",
  },
  tableBody: {
    color: "#070D23 !important",
    fontWeight: "500 !important",
  },
  menu: {
    padding: "10px 50px  !important",
    borderBottom: "1.5px solid #F7F7F7 !important",
  },
  userImg: {
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    marginRight: '5px',
    objectFit: 'cover'
  },
  downloadBtn: {
    border: 0,
    margin: 0,
    cursor: 'pointer',
    backgroundColor: '#2e7d32',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    padding: '12px 18px',
    borderRadius: '4px',
    color: 'white',
    marginBottom: 25,
  },
  closeButton: {
    float: 'right'
  },
  dialog: {
    // top: 10,
    borderRadius: 24,
    fontFamily: 'Arial,Helvetica,sans-serif',
    [theme.breakpoints.up("md")]: {
      minWidth: "800px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "95%",
      padding: 10,
    },
    '& .MuiGrid-container': {
      marginTop: 10
    },
    '& .MuiGrid-item': {
      paddingTop: "5px !important"
    },
    '& h2.MuiTypography-h6': {
      color: '#142669'
    },
    '& p': {
      color: '#4f4f4f',
      fontSize: 15
    },
    '& .mt5': {
      marginTop: 3
    }
  },
  actionBtn: {
    marginLeft: "10px !important",
  },
  flex: {
    display: 'flex',
  },
  sendButton: {
    minWidth: "200px !important",
  },
  sendButtonSent: {
    minWidth: "200px !important",
    backgroundColor: "#cc7191 !important",
  },
  sendButtonNotSent: {
    backgroundColor: "##142669 !important",
    minWidth: "200px !important",
  },
  sendBtn: {
    minWidth: '200px !important',
    backgroundColor: '#1976d2 !important',
    color: 'white !important',
    opacity: 1,
    transition: 'all 0.4s ease-in-out',
    margin: '10px auto !important',
    '&:hover': {
      opacity: 0.8,
      transition: 'all 0.4s ease-in-out',
    }
  },
  mainTitle: {
    marginTop: 45,
    marginBottom: 0,
    color: '#142669',
    fontSize: '1.3rem',
    '&:first-child': {
      marginTop: 0
    }
  },
  subTitle: {
    marginTop: 20,
    marginBottom: 0,
    color: '#142669',
    fontSize: '1.1rem'
  },
  photoGallery: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
    borderRadius: '15px',
    marginRight: 10
  },
  imgDownload: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    textAlign: 'center'
  },
  addNewCode: {
    marginLeft: 'auto !important',
    marginRight: '20px !important',
  },
  rounded: {
    borderRadius: '10px',
    objectFit: 'cover',
    maxWidth: '100px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  btnGroup: {
    display: "flex",
    "& button": {
      marginRight: "5px"
    }
  },
  userCell : {
    display: "flex",
    '& div' : {
      width: "80px",
      marginRight: "10px"
    }
  }
}));