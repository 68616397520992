import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  tableHeaders,
} from "../config/constant";
import { useStyles } from "../theme/style";
import { GridField } from "../components/GridField";
import { getCommentReports } from "services/commentService";
import { ellipsis, htmlToText } from "utils/string";
import TableHeader from "components/TableHeader";
import Paging from "components/Paging";
import { downloadExcel } from "utils/download";
import { LoadingButton } from "@material-ui/lab";
import { Description } from "@material-ui/icons";

export default function ManageReports() {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      setIsLoading(true);
      const res = await getCommentReports({ skip: curPage - 1, take: pageSize });
      if (res) {
        setComments(res.data);
        setTotal(res.total);
      } else {
        setComments([]);
        setTotal(0);
      }
      setIsLoading(false);
    })(); // eslint-disable-next-line
  }, []);

  const handleDownload = async () => {
    setDownloading(true);
    const res = await getCommentReports();
    if (res && res.total > 0) {
      downloadExcel('Reports', res.data);
    }
    setDownloading(false);
  }

  const handleClickDetails = (index) => {
    setSelectedIndex(index);
    setDialogOpen(true);
  };

  const onPageChange = (page) => {
    setCurPage(page);
    (async function () {
      setIsLoading(true);
      const res = await getCommentReports({ skip: (page - 1) * pageSize, take: pageSize });
      if (res) {
        setComments(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const onRowsPerPageChange = (newSize) => {
    setCurPage(1);
    setPageSize(newSize);
    (async function () {
      setIsLoading(true);
      const res = await getCommentReports({ skip: 0, take: newSize });
      if (res) {
        setComments(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader fields={tableHeaders.report.fields} />
          <TableBody>
            {comments?.map(
              (item, index) => (
                item.Read !== true &&
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    className={classes.tableBody}
                    component="th"
                    scope="row"
                  >
                    {index + 1 + (curPage - 1) * pageSize}
                  </TableCell>
                  <TableCell
                    className={classes.tableBody}
                    component="th"
                    scope="row"
                  >
                    {item.Type}
                  </TableCell>
                  <TableCell
                    className={classes.tableBody}
                    component="th"
                    scope="row"
                  >
                    {item.ReportedBy}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {item.Location}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {ellipsis(htmlToText(item.ReportReason || ""), 140)}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {ellipsis(htmlToText(item.ReportAdditionalInfo || ""), 140)}
                    {/* {item.TypeId < 3
                      ? <Link underline={"none"} href={`${process.env.REACT_APP_FRONT_BASE_URL}/discuss/convos/${item.LocationId}`}>View Community</Link>
                      : <Link underline={"none"} href={`${process.env.REACT_APP_FRONT_BASE_URL}/article/${item.LocationId}`}>View Article</Link>} */}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {moment(item.ReportedDate).format("LL")}
                  </TableCell>
                  <TableCell className={classes.btnGroup}>
                    <Tooltip title="Detail">
                      <IconButton
                        color="primary"
                        onClick={() => handleClickDetails(index)}
                      >
                        <Description />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <LoadingOverlay active={isLoading} spinner={true} text="Loading...">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <LoadingButton
          loading={downloading}
          onClick={handleDownload}
          variant="contained"
          color="success"
        >Download</LoadingButton>
        <Grid item>Total: {total}</Grid>
      </Grid>
      {renderTable()}
      <Paging
        onPageSizeChange={onRowsPerPageChange}
        onPageChange={onPageChange}
        total={total}
        curPage={curPage}
      />
      <Dialog
        onClose={() => setDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setDialogOpen(false)}
        >
          Report details (ID: {comments[selectedIndex]?.Id})
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <GridField title="Title" col={6}>{comments[selectedIndex]?.Location}</GridField>
            <GridField title="Author" col={6}>{comments[selectedIndex]?.Author}</GridField>
          </Grid>
          <Grid container>
            <GridField title="Posted to" col={6}>{comments[selectedIndex]?.PostedTo}</GridField>
          </Grid>
          <Grid container spacing={3}>
            <GridField title="Posted By" col={6}>{comments[selectedIndex]?.Author}</GridField>
            <GridField title="Posted At" col={6}>{moment(comments[selectedIndex]?.CreatedDate).format("LL")}</GridField>
          </Grid>
          <Grid>
            <GridField title="Content" isHtml={true} col={12}>{comments[selectedIndex]?.Content}</GridField>
          </Grid>
          <Grid>
            <GridField title="Report Reason" isHtml={true} col={12}>{comments[selectedIndex]?.ReportReason}</GridField>
          </Grid>
          <Grid>
            <GridField title="Additional Infomation" isHtml={true} col={12}>{comments[selectedIndex]?.ReportAdditionalInfo}</GridField>
          </Grid>
          <Grid container spacing={3}>
            <GridField title="Reported By" col={6}>{comments[selectedIndex]?.ReportedBy}</GridField>
            <GridField title="Reported At" col={6}>{moment(comments[selectedIndex]?.ReportedDate).format("LL")}</GridField>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setDialogOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingOverlay>
  );
}