import React, { useEffect, useRef, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Snackbar,
  Button,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  TextareaAutosize,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { tableHeaders } from "../config/constant";
import InputBase from "@material-ui/core/InputBase";
import { styled } from "@material-ui/styles";
import { useStyles } from "../theme/style";
import { GridField } from "../components/GridField";
import AWSUploadFile from "components/FileUploadService";
import KitImg from "components/kitImg";
import {
  addPawffice,
  getPawffice,
  deletePawffice,
} from "services/pawfficeService";
import TableHeader from "components/TableHeader";
import Alert from "components/Alert";
import { downloadExcel } from "utils/download";
import { LoadingButton } from "@material-ui/lab";
import { Description, Delete } from "@material-ui/icons";
import moment from "moment";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#AFB3B6",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 4),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    backgroundColor: "#F5F9FC",
    [theme.breakpoints.up("md")]: {
      width: "25ch",
    },
  },
}));

export default function ManagePawfficehours() {
  const classes = useStyles();
  const [pawfficehours, setPawfficehours] = useState([]);
  const [selectedPawfficehour, setSelectedPawfficehour] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(true);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const fileInput = useRef(null);
  const [curImage, setCurImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [inputData, setInputData] = useState({
    Title: "",
    Description: "",
    EventDate: moment(Date.now() + 24 * 3600 * 1000).format("yyyy-MM-DDThh:MM"),
    TotalSpots: 20,
  });
  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const res = await getPawffice();
      if (res?.length) {
        setPawfficehours(res);
      }
      setIsLoading(false);
    })();
  }, []);

  const handleDownload = async () => {
    setDownloading(true);
    const res = await getPawffice({});
    if (res && res.length > 0) {
      downloadExcel("pawffice", res);
    }
    setDownloading(false);
  };

  const handleClickAddAction = async () => {
    setIsLoading(true);
    const res = await addPawffice({
      ...inputData,
      AvailableSpots: inputData.TotalSpots,
    });

    if (res) {
      let tmpPawffice = pawfficehours;
      tmpPawffice.push(res.data);
      setPawfficehours(tmpPawffice);
      setUpdateFlag(!updateFlag);
      setMessage("Added a pawfficehour successfully!");
      setIsLoading(false);
      setAddDialogOpen(false);
    }
  };

  const handleDeleteAction = async (id) => {
    console.log("index", id);
    setIsLoading(true);
    console.log(pawfficehours, "pawfficehours");

    const res = await deletePawffice(id);
    if (res) {
      const filteredPawfficeHours = pawfficehours.filter(
        (item) => item.Id !== id
      );
      setPawfficehours(filteredPawfficeHours);
      setMessage("Code deleted successfully!");
    }
    setIsLoading(false);
  };

  const handleClickDetails = (index) => {
    setSelectedPawfficehour(pawfficehours[index]);
    setDialogOpen(true);
  };

  const setNewPawfficehourData = (key, value) => {
    let tmpInput = {
      ...inputData,
      [key]: value,
    };

    setInputData(tmpInput);
  };

  const handleClickBrowse = (e) => {
    const file = e.currentTarget.files[0];
    setUploading(true);
    getFileURL(file);
  };

  const getFileURL = async (image) => {
    const res = await AWSUploadFile(image, `images/profiles`);
    if (res.location) {
      setUploading(false);
      setCurImage(res.location);
      setNewPawfficehourData("Photo", res.location);
    }
  };

  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader fields={tableHeaders.pawfficehour.fields} />
          <TableBody>
            {pawfficehours?.map(
              (item, index) =>
                item && (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className={classes.tableBody} align="left">
                      {index + 1}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {item?.Title}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {item?.EventDate}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {item?.AvailableSpots}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {item?.TotalSpots}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {item?.NextPawfficeId}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      <Tooltip title="Detail">
                        <IconButton
                          color="primary"
                          onClick={() => handleClickDetails(index)}
                        >
                          <Description />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => handleDeleteAction(item.Id)}
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>

        <Snackbar open={false} autoHideDuration={6000}>
          <Alert severity="success" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </TableContainer>
    );
  };

  return (
    <LoadingOverlay active={isLoading} spinner={true} text="Loading...">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <LoadingButton
          loading={downloading}
          onClick={handleDownload}
          variant="contained"
          color="success"
        >
          Download
        </LoadingButton>
        <Button
          color="primary"
          onClick={() => setAddDialogOpen(true)}
          className={classes.addNewCode}
        >
          Add New
        </Button>
      </Box>
      {renderTable()}

      <Dialog
        onClose={() => setDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle id="customized-dialog-title">
          Details (ID: {selectedPawfficehour?.Id})
        </DialogTitle>
        <DialogContent dividers>
          <GridField title="Title" col={12}>
            {selectedPawfficehour?.Title}
          </GridField>
          {selectedPawfficehour?.Description && (
            <GridField title="Description" isHtml={true} col={12}>
              {selectedPawfficehour?.Description}
            </GridField>
          )}
          {selectedPawfficehour?.CreatedBy && (
            <Grid container spacing={3}>
              <GridField title="Created By" col={6}>
                <strong>Name: </strong>
                {selectedPawfficehour?.CreatedBy.Name}
              </GridField>
              <GridField title="Profile Image" col={6}>
                <KitImg
                  src={selectedPawfficehour?.CreatedBy.ProfileImage}
                  alt="article"
                  className={classes.userImg}
                />
              </GridField>
            </Grid>
          )}
          <Grid container spacing={3}>
            <GridField title="Event Date" col={6}>
              {selectedPawfficehour?.EventDate}
            </GridField>
            <GridField title="Posted Date" col={6}>
              {selectedPawfficehour?.PostedDate}
            </GridField>
          </Grid>
          <Grid container spacing={3}>
            <GridField title="Available Spots" col={6}>
              {selectedPawfficehour?.AvailableSpots}
            </GridField>
            <GridField title="Total Spots" col={6}>
              {selectedPawfficehour?.TotalSpots}
            </GridField>
            <GridField title="Next Pawffice Id" col={12}>
              {selectedPawfficehour?.NextPawfficeId}
            </GridField>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => setDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={addDialogOpen}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle id="customized-dialog-title">
          Add New Pawfficehour
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <GridField col={6} title="Title">
              <StyledInputBase
                value={inputData.Title}
                placeholder="Title"
                onChange={(e) =>
                  setNewPawfficehourData("Title", e.target.value)
                }
              />
            </GridField>
            <GridField col={6} title="Event Date">
              <TextField
                id="datetime-local"
                type="datetime-local"
                value={inputData.EventDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  setNewPawfficehourData("EventDate", e.target.value)
                }
              />
            </GridField>
          </Grid>
          <Grid container>
            <GridField col={12} title="Description">
              <TextareaAutosize
                aria-label="Description"
                value={inputData.Description}
                placeholder="Description"
                style={{ width: "100%", height: 150 }}
                onChange={(e) =>
                  setNewPawfficehourData("Description", e.target.value)
                }
              />
            </GridField>
          </Grid>
          <Grid container>
            <GridField col={12} title="Image">
              <input
                type="file"
                id="imageFile"
                ref={fileInput}
                style={{ display: "none" }}
                onChange={handleClickBrowse}
                onKeyUp={handleClickBrowse}
                accept="image/*"
              />
              <Button color="warning" onClick={() => fileInput.current.click()}>
                Add New Image
              </Button>
              {uploading ? (
                <img src="/assets/loading.gif" width="30px" alt="uploading" />
              ) : null}
            </GridField>
          </Grid>
          {curImage && (
            <Grid container>
              <KitImg
                src={curImage}
                kitwidth={200}
                alt="article"
                className={classes.rounded}
              />
            </Grid>
          )}
          <Grid container>
            <GridField col={6} title="Host">
              <StyledInputBase
                value={inputData.Host}
                placeholder="Host name"
                onChange={({ target: { value } }) =>
                  setNewPawfficehourData("Host", value)
                }
              />
            </GridField>
            <GridField col={6} title="Total Spots">
              <StyledInputBase
                value={inputData.TotalSpots}
                placeholder="Total Spots"
                onChange={({ target: { value } }) =>
                  setNewPawfficehourData("TotalSpots", value)
                }
              />
            </GridField>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleClickAddAction()}
            color="primary"
            className={classes.sendBtn}
          >
            Add
          </Button>
          <Button onClick={() => setAddDialogOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingOverlay>
  );
}
