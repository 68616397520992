import React, { useEffect, useRef, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Snackbar,
  Button,
  Box,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import InputBase from "@material-ui/core/InputBase";
import { styled } from "@material-ui/styles";
import { REFERRAL_CODE_NOT_SENT, REFERRAL_CODE_SENT, tableHeaders } from "config/constant";
import TabPanel from "../components/TabPanel";
import { GridField } from "../components/GridField";
import { useStyles } from "../theme/style";
import { activateCode, addCode, codeSendWithId, codeSendWithEmail, deleteCode, getCapturedEmails, getCodeDetail, getCodes } from "services/codeService";
import TableHeader from "components/TableHeader";
import { a11yProps } from "utils/mui";
import Alert from "components/Alert";
import { ReferralCodeEmail } from "services/emailService";
import Paging from "components/Paging";
import { downloadExcel } from "utils/download";
import { LoadingButton } from "@material-ui/lab";
import { Close, Delete, Description, Done } from "@material-ui/icons";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#AFB3B6",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 4),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    backgroundColor: "#F5F9FC",
    [theme.breakpoints.up("md")]: {
      width: "25ch",
    },
  },
}));

export default function ManageReferralCodes() {
  const classes = useStyles();
  const [emails, setEmails] = useState([]);
  const [codes, setCodes] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [curStatus, setCurStatus] = useState('codes');
  const [inputEmail, setInputEmail] = useState('');
  const [inputCode, setInputCode] = useState('');
  const fileInput = useRef(null);
  const [fileContent, setFileContent] = useState([]);
  const [detailData, setDetailData] = useState(null);
  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setCurPage(1);
    (async function () {
      setIsLoading(true);
      if (curStatus === 'codes') {
        const codeResponse = await getCodes({
          skip: curPage - 1,
          take: pageSize
        });
        if (codeResponse?.data?.length > 0) {
          setCodes(codeResponse.data);
          setTotal(codeResponse.total);
        } else {
          setCodes([]);
          setTotal(0);
        }
      } else {
        const emailResponse = await getCapturedEmails({
          skip: curPage - 1,
          take: pageSize
        });
        if (emailResponse?.data?.length > 0) {
          setEmails(emailResponse.data);
          setTotal(emailResponse.total);
        } else {
          setEmails([]);
          setTotal(0);
        }
      }
      setIsLoading(false);
    })(); // eslint-disable-next-line
  }, [curStatus]);

  const handleDownload = async () => {
    setDownloading(true);
    if (curStatus === 'codes') {
      const codeResponse = await getCodes();
      if (codeResponse && codeResponse.total > 0) {
        downloadExcel('Refferal codes', codeResponse.data);
      }
    } else {
      const emailResponse = await getCapturedEmails();
      if (emailResponse && emailResponse.length) {
        downloadExcel('Captured emails', emailResponse);
      }
    }
    setDownloading(false);
  }

  const handleGetDetail = async (index) => {
    const res = await getCodeDetail(codes[index].ReferralCode);
    if (res) setDetailData(res.data);
  }

  const handleAddCode = async () => {
    if (inputCode !== '') {
      setIsLoading(true);
      const res = await addCode(inputCode, true);
      if (res) {
        let index = codes.findIndex(elem => elem.ReferralCode === inputCode);
        if (res.IsCreated || index < 0) {
          codes.push({
            Id: res?.Id,
            ReferralCode: res?.ReferralCode,
            UsedCount: 0,
            Status: true
          });
        } else {
          codes[index].Status = true;
        }
        setMessage(`Referral Code is ${res.IsCreated ? "added" : "updated"} Successfully!`);
        setInputCode('');
      }
      setIsLoading(false);
      setDialogOpen(false);
    }
  }

  const handleCloseDetailsDialog = async () => {
    if (inputEmail !== '' && inputCode !== '') {
      const res = await codeSendWithEmail(inputEmail, inputCode);
      if (res) {
        let currentEmail = { Id: res.data.Id, EmailAddress: inputEmail, status: "Referral Code not sent yet" };
        let tempEmails = emails;
        tempEmails.push({
          ...currentEmail,
          status: REFERRAL_CODE_SENT,
        });
        setEmails(tempEmails);
        setMessage("Referral Code Sent Successfully!");
        await ReferralCodeEmail(currentEmail.EmailAddress, res.data?.ReferralCode);
        setInputEmail('');
        setInputCode('');
      }
      setDialogOpen(false);
    }
  };

  const handleSetActive = async (index, value) => {
    const curCode = codes[index];
    setIsLoading(true);
    const res = await activateCode(curCode.Id, curCode.ReferralCode, value);
    if (res) {
      setCodes(origin => origin.map((elem, idx) => idx === index ? { ...elem, Status: value } : elem));
      setMessage("Referral Code Status is updated Successfully!");
    }
    setIsLoading(false);
  }

  const handleDeleteCode = async (index) => {
    const delCode = codes[index];
    setIsLoading(true);
    const res = await deleteCode(delCode.Id);
    if (res) {
      let tempCodes = codes;
      tempCodes.splice(index, 1);
      setCodes(tempCodes);
      setMessage("Code deleted successfully!");
    }
    setIsLoading(false);
  }

  const handleActivateWithFile = async () => {
    if (fileContent.length > 0) {
      let countAdded = 0;
      let countUpdated = 0;
      await Promise.all(fileContent.map(async code => {
        const res = await addCode(code, true);
        if (res) {
          let index = codes.findIndex(elem => elem.ReferralCode === code);
          if (res.IsCreated || index < 0) {
            codes.push({
              Id: res?.Id,
              ReferralCode: res?.ReferralCode,
              UsedCount: 0,
              Status: true
            });
          } else {
            codes[index].Status = true;
          }
          if (res.IsCreated) {
            countAdded++;
          } else {
            countUpdated++;
          }
        }
      }))
      setMessage(`${countAdded ? `${countAdded} codes added` : ""}
        ${(countAdded && countUpdated) ? " and " : ""}
        ${countUpdated ? `${countUpdated} codes updated` : ""}!`);
    }
  }

  const handleRead = () => {
    if (fileInput?.current?.files[0]) {
      let reader = new FileReader();
      reader.readAsText(fileInput.current.files[0], "UTF-8");
      reader.onload = function (e) {
        if (e.target.result) {
          setFileContent(e.target.result.split(/[\s,]/)?.filter(e => e));
        }
      }
    }
  }

  const onRowsPerPageChange = (newSize) => {
    setCurPage(1);
    setPageSize(newSize);
    (async function () {
      setIsLoading(true);
      if (curStatus === "codes") {
        const res = await getCodes({ skip: 0, take: newSize });
        if (res) {
          setCodes(res.data);
          setTotal(res.total);
        }
      } else {
        const res = await getCapturedEmails({ skip: 0, take: newSize });
        if (res) {
          setEmails(res.data);
          setTotal(res.total);
        }
      }
      setIsLoading(false);
    })();
  }

  const onPageChange = (page) => {
    setCurPage(page);
    (async function () {
      setIsLoading(true);
      if (curStatus === "codes") {
        const res = await getCodes({ skip: (page - 1) * pageSize, take: pageSize });
        if (res) {
          setCodes(res.data);
          setTotal(res.total);
        }
      } else {
        const res = await getCapturedEmails({ skip: (page - 1) * pageSize, take: pageSize });
        if (res) {
          setEmails(res.data);
          setTotal(res.total);
        }
      }
      setIsLoading(false);
    })();
  }

  const Status = ({ email }) => {
    const [code, setCode] = useState("");
    let status = (email.status === REFERRAL_CODE_NOT_SENT) ? "Send" : (email.status === REFERRAL_CODE_SENT) ? "Resend" : null;
    const handleClickSend = async () => {
      setIsLoading(true);
      await ReferralCodeEmail(email.EmailAddress, code);
      const res = await codeSendWithId(email.Id);
      if (res) {
        setEmails(origin => origin.map(elem => (elem.Id === email.Id) ? { ...email, status: REFERRAL_CODE_SENT } : elem));
      }
      setIsLoading(false);
    };
    return (
      <>
        <TableCell className={classes.tableBody} align="left">
          <StyledInputBase
            value={code}
            placeholder="Enter Referral Code"
            inputProps={{ "aria-label": "search" }}
            className={classes.sendButton}
            onChange={(e) => setCode(e.target.value)}
          />
        </TableCell>
        <TableCell>
          <Button
            color="primary"
            variant="contained"
            className={status === "Send" ? classes.sendButtonNotSent : classes.sendButtonSent}
            onClick={() => handleClickSend()}
            label="Enter Referel Code"
          >
            {status}
          </Button>
        </TableCell>
      </>
    );
  };

  const UserTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader fields={tableHeaders.captured.fields} />
          <TableBody>
            {emails?.map((email, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classes.tableBody} align="left">{index + 1 + (curPage - 1) * pageSize}</TableCell>
                <TableCell className={classes.tableBody} align="left">{email.EmailAddress}</TableCell>
                <TableCell className={classes.tableBody} align="left">{email.status === "Referral Code Sent Already" ? "Sent" : "Not Sent"}</TableCell>
                <Status email={email} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Snackbar
          open={message}
          autoHideDuration={6000}
          onClose={() => setMessage("")}
        >
          <Alert
            onClose={() => setMessage("")}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </TableContainer>
    )
  }

  const CodeTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader fields={tableHeaders.codes.fields} />
          <TableBody>
            {codes?.map((code, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classes.tableBody} align="left">{index + 1 + (curPage - 1) * pageSize}</TableCell>
                <TableCell className={classes.tableBody} align="left">{code.ReferralCode}</TableCell>
                <TableCell className={classes.tableBody} align="left">{code.UsedCount}</TableCell>
                <TableCell className={classes.tableBody} align="left">
                  {code.Status ?
                    (<span style={{ color: 'green' }}>Active</span>) :
                    (<span style={{ color: 'red' }}>Deactivated</span>)
                  }
                </TableCell>
                <TableCell className={classes.tableBody} align="left">
                  <Tooltip title="Detail">
                    <IconButton
                      color="primary"
                      onClick={() => handleGetDetail(index)}
                    >
                      <Description />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={code.Status ? "Deactivate" : "Activate"}>
                    <IconButton
                      onClick={() => handleSetActive(index, !code.Status)}
                      color={code.Status ? undefined : "success"}
                    >
                      {code.Status ? <Close /> : <Done />}
                    </IconButton>
                  </Tooltip>


                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => handleDeleteCode(index)}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Snackbar
          open={message}
          autoHideDuration={6000}
          onClose={() => setMessage('')}
        >
          <Alert
            onClose={() => setMessage('')}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </TableContainer>
    )
  }

  return (
    <>
      <input
        type="file"
        id="fileToRead"
        ref={fileInput}
        style={{ display: 'none' }}
        accept=".csv, .txt"
        onChange={handleRead}
      />
      <LoadingOverlay active={isLoading} spinner={true} text="Loading...">
        <LoadingButton
          loading={downloading}
          onClick={handleDownload}
          variant="contained"
          color="success"
        >Download</LoadingButton>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={(e, v) => setValue(v)} aria-label="basic tabs example">
            <Tab label="Codes" {...a11yProps(0)} onClick={() => setCurStatus('codes')} />
            <Tab label="Users" {...a11yProps(1)} onClick={() => setCurStatus('users')} />
            <div className={classes.addNewCode}>
              {!!fileContent.length && <Button color="primary" onClick={() => handleActivateWithFile()} >Activate</Button>}
              <Button color="primary" onClick={() => fileInput?.current?.click()}>Upload File</Button>
              <Button color="primary" onClick={() => setDialogOpen(true)}>Add New Code</Button>
            </div>
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}><CodeTable /></TabPanel>
        <TabPanel value={value} index={1}><UserTable /></TabPanel>
        <Paging
          onPageSizeChange={onRowsPerPageChange}
          onPageChange={onPageChange}
          total={total}
          curPage={curPage}
        />
        <Dialog
          onClose={handleCloseDetailsDialog}
          aria-labelledby="customized-dialog-title"
          open={dialogOpen}
          classes={{ paper: classes.dialog, }}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDetailsDialog}
          >
            Add New Code
          </DialogTitle>
          <DialogContent dividers>
            <Grid container>
              {curStatus === 'users' ?
                (<GridField col={6} title="Email">
                  <StyledInputBase
                    value={inputEmail}
                    placeholder="Email"
                    inputProps={{ "aria-label": "email" }}
                    onChange={(e) => setInputEmail(e.target?.value)}
                  />
                </GridField>) : null
              }
              <GridField col={6} title="Referral Code">
                <StyledInputBase
                  value={inputCode}
                  placeholder="Referral Code"
                  inputProps={{ "aria-label": "code" }}
                  onChange={(e) => setInputCode(e.target?.value)}
                />
              </GridField>
            </Grid>
          </DialogContent>
          <DialogActions>
            {curStatus === 'users' ? (
              <Button
                autoFocus
                onClick={handleCloseDetailsDialog}
                color="primary"
                className={classes.sendBtn}
              >
                SEND
              </Button>
            ) : (
              <Button
                autoFocus
                onClick={handleAddCode}
                color="primary"
                className={classes.sendBtn}
              >
                ADD
              </Button>
            )}
            <Button
              onClick={() => setDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => setDetailData(null)}
          aria-labelledby="detail-dialog"
          open={detailData}
        >
          <DialogTitle>Code Detail</DialogTitle>
          <DialogContent>
            <Table>
              <TableHeader fields={["No", "Name", "Email", "Program Name"]} />
              {detailData?.length > 0 &&
                <TableBody>
                  {detailData.map((datum, idx) => (
                    <TableRow>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{datum.Name}</TableCell>
                      <TableCell>{datum.email}</TableCell>
                      <TableCell>{datum.programName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              }
            </Table>
            {!detailData?.length &&
              <Grid container><Grid item sx={{ mx: "auto", mt: 2 }}>No data</Grid></Grid>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDetailData(null)} variant="contained" sx={{ mx: "auto", mb: 2 }}>Close</Button>
          </DialogActions>
        </Dialog>
      </LoadingOverlay>
    </>
  );
}
