import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const thumb = {
    display: 'block',
    width: '100%',
    height: '100%',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: '100%',
    maxHeight: '160px',
    minHeight:'100px',
    height:'100%',
};

const useStyles = makeStyles((theme) => ({
    fileUploadCard: {
        background: '#FFFFFF',
        border: '3px dashed #e3e4e5',
        boxSizing: 'border-box',
        borderRadius: '28px',
        height: '193px',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none !important',
        [theme.breakpoints.down('md')]: {
            borderRadius: '10px',
        }
    },

    fileUploadBtn: {
        background: '#F5F9FC !important',
        borderRadius: '80px !important',
        opacity: '100% !important'
    },

    addNoteInput: {
        background: '#FFFFFF',
        border: '1.5px solid #ebebeb',
        boxSizing: 'border-box',
        borderRadius: '17px',
        width: '100%',
        margin: '32px 0',
        padding: '19px 21px',
        maxWidth: '100%',
        [theme.breakpoints.down('md')]: {
            borderRadius: '10px',
        }

    },
    btnGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '12px',
        flexWrap: 'wrap',

        [theme.breakpoints.down('md')]: {
            '& button': {
                marginTop: '10px !important',
                width: '100%'
            },
        }
    }
}));

export default function BasicDropzone({setFile, ...props}) {
    const classes = useStyles();

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt="file preview"
                />
            </div>
        </div>
    ));

    useEffect(() => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
        setFile(files[0]);
    }, [files, setFile]);

    return (
        <section className="container">
            <div
                className={classes.fileUploadCard}
                {...getRootProps({
                    style: {
                        width: '100%',
                        borderWidth: '3px',
                        borderColor: '#e3e4e5',
                        borderStyle: 'dashed',
                        borderRadius: '5px',
                        // padding: '10px'
                    }
                })}
            >
                <input {...getInputProps()} />
                {files.length !== 0 ? <div>
                    {thumbs}
                </div> : (<> <IconButton className={classes.fileUploadBtn}>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.3">
                            <path d="M19.6001 11.4L1.6001 11.4" stroke="#070D23" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.6001 20.4L10.6001 2.40002" stroke="#070D23" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                    </svg>
                </IconButton>
                    <Typography variant="h6" component="h6" style={{ marginTop: '1rem' }}>
                        Add Image
                    </Typography>
                </>)}
            </div>
        </section>
    );
}

