import { convertQuery } from "utils/string";
import { getHeader, getInstance } from "./basicService";

export const getArticles = async function (query) {
  let res = null;
  await getInstance()
    .get(`${process.env.REACT_APP_API_BASE_URL}/api/breederresource/article${convertQuery(query)}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Articles get error:", err);
    })
  return res;
}

export const deleteArticle = async function (id) {
  let res = null;
  await getInstance()
    .put(`${process.env.REACT_APP_API_BASE_URL}/api/breederresource/article/${id}/delete`, {}, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Articles delete error:", err);
    })
  return res;
}

export const addArticle = async function (data) {
  let res = null;
  await getInstance()
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/breederresource/article`, data, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Articles add error:", err);
    })
  return res;
}

export const updateArticle = async function (data) {
  let res = null;
  await getInstance()
    .put(`${process.env.REACT_APP_API_BASE_URL}/api/breederresource/article`, data, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Articles update error:", err);
    })
  return res;
}

export const showHomeArticle = async function (id) {
  let res = null;
  await getInstance()
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/admin/article/${id}/show-on-home`, {}, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Article set home error:", err);
    })
  return res;
}

export const getSuggestedResources = async function (query) {
  let res = null;
  await getInstance()
    .get(`${process.env.REACT_APP_API_BASE_URL}/api/admin/resource-submission${convertQuery(query)}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Suggested resources get error:", err);
    })
  return res;
}