import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Editor from '@draft-js-plugins/editor';
import { RichUtils, getDefaultKeyBinding, EditorState, AtomicBlockUtils } from 'draft-js'
import createImagePlugin from '@draft-js-plugins/image';
import createVideoPlugin from '@draft-js-plugins/video';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import createLinkPlugin from '@draft-js-plugins/anchor';
import AWSUploadFile from './FileUploadService';
import '@draft-js-plugins/image/lib/plugin.css';
import { convertImgUrl } from 'utils/string';
import { CameraAltOutlined, FormatListBulleted, FormatListNumbered, LinkSharp, CheckCircleOutline, CancelOutlined } from '@material-ui/icons';
import './RichEditor.scss';
import '@draft-js-plugins/emoji/lib/plugin.css';

const STYLE_MAP = {
  CODE: {
    CODE: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  }
}

const INLINE_STYLES = [
  { label: "B", style: "BOLD" },
  { label: "I", style: "ITALIC" },
  { label: "U", style: "UNDERLINE" },
]

const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "List", style: "unordered-list-item", icon: <FormatListBulleted /> },
  { label: "Numbered List", style: "ordered-list-item", icon: <FormatListNumbered /> },
];

const RichEditor = (props) => {
  const { editorState, setEditorState, initialStyle } = props;
  const imagePlugin = createImagePlugin();
  const videoPlugin = createVideoPlugin();
  const emojiPlugin = createEmojiPlugin({
    theme: {
      ...defaultTheme,
      emojiSelectButton: "emojiSelect",
      emojiSelectButtonPressed: "emojiSelect activeButton"
    }
  });
  const linkPlugin = createLinkPlugin();
  const editorRef = useRef(null);
  const getEditorState = () => editorState;
  emojiPlugin.initialize({
    getEditorState: getEditorState,
    setEditorState: setEditorState
  });
  const { EmojiSelect } = emojiPlugin;
  const plugins = [imagePlugin, videoPlugin, emojiPlugin, linkPlugin];
  // eslint-disable-next-line
  const [loadingUpload, setLoadingUpload] = useState(false);
  const inputImage = useRef(null);
  const [linkString, setLinkString] = useState("");
  const [openLinkInput, setOpenLinkInput] = useState(false);

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  }

  const getFileURL = async (file) => {
    let typeFile = file.type.split("/")[0];
    const res = await AWSUploadFile(file, `images/others`);
    if (res.location) {
      const newEditorState = insertImage(editorState, (typeFile === "video") ? res.location : convertImgUrl(res.location, 300), typeFile);
      setEditorState(newEditorState);
      setLoadingUpload(false);
    }
  };

  const handleClick = (e) => {
    const file = e.currentTarget.files[0];
    getFileURL(file);
    setLoadingUpload(true);
  }

  const insertImage = (editorState, src, typeFile) => {
    const { types } = videoPlugin;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      typeFile === "video" ? types.VIDEOTYPE : "image",
      "IMMUTABLE",
      { src }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  const StyleButton = ({ active, onToggle, label, style, icon }) => {
    return (
      <span
        className={[
          "styleButton",
          active && "activeButton",
          style === "BOLD" && "bold",
          style === "ITALIC" && "italic",
          style === "UNDERLINE" && "underline",
        ].join(" ")}
        onMouseDown={(e) => {
          e.preventDefault();
          onToggle(style);
        }}
      >
        {icon ? icon : label}
      </span>
    );
  }

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4);
      if (newEditorState !== editorState) {
        setEditorState(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  const toggleInlineStyle = (inlineStyle) => {
    if (initialStyle?.includes(inlineStyle)) {
      if (editorState?.getCurrentInlineStyle()?.has(inlineStyle)) return;
    }
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const toggleBlockStyle = (blockStyle) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockStyle));
  };

  const getBlockStyle = (block) =>
    block.getType() === "blockquote" ? ".blockQuote" : null;

  const isCurInlineStyle = (type) => {
    return editorState?.getCurrentInlineStyle()?.has(type.style)
  }

  const isCurBlockStyle = (type) => {
    let selection = editorState.getSelection();
    if (selection) {
      return editorState
        .getCurrentContent()
        .getBlockForKey(editorState.getSelection().getStartKey())
        .getType() === type.style
    }
    return false;
  }

  const getLink = () => {
    let selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      return url;
    }
  }

  const isCurLink = () => {
    let selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      return !!linkKey;
    }
    return false;
  }

  const handleSetLink = (isRemove = false) => {
    if (isRemove || !linkString) {
      const selection = editorState.getSelection();
      if (!selection.isCollapsed()) {
        setEditorState(RichUtils.toggleLink(editorState, selection, null));
      }
    } else {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url: linkString }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      let nextEditorState = EditorState.set(editorState,
        { currentContent: contentStateWithEntity }
      );

      nextEditorState = RichUtils.toggleLink(nextEditorState,
        nextEditorState.getSelection(), entityKey
      );
      setEditorState(nextEditorState);
    }
    setOpenLinkInput(false);
  }

  const handleFocus = () => {
    setTimeout(() => {
      if (initialStyle?.length) {
        initialStyle.forEach(st => {
          if (!editorState?.getCurrentInlineStyle()?.has(st)) toggleInlineStyle(st);
        })
      }
    }, 0)
  }

  return (
    <div className="richEditor">
      <input
        ref={inputImage}
        type="file"
        style={{ display: "none" }}
        accept="image/*, video/*"
        onChange={handleClick}
      />
      <div className="stylingContainer">
        {INLINE_STYLES.map(type => (
          <StyleButton
            key={type.label}
            active={isCurInlineStyle(type)}
            label={type.label}
            onToggle={toggleInlineStyle}
            style={type.style}
          />
        ))}
        {BLOCK_TYPES.map((type) => (
          <StyleButton
            key={type.label}
            active={isCurBlockStyle(type)}
            label={type.label}
            onToggle={toggleBlockStyle}
            style={type.style}
            icon={type.icon}
          />
        ))}
        <div className='inputLink'>
          <StyleButton
            key="link"
            active={isCurLink()}
            onToggle={() => { setOpenLinkInput(true); setLinkString(getLink()) }}
            icon={<LinkSharp />}
          />
          {openLinkInput && <div>
            <input value={linkString} onChange={(e) => setLinkString(e.target.value)} />
            <CheckCircleOutline className='check' onClick={() => handleSetLink(false)} />
            <CancelOutlined className='cancel' onClick={() => handleSetLink(true)} />
          </div>}
        </div>
        <EmojiSelect closeOnEmojiSelect />
        <span className="styleButton">
          <span onClick={() => inputImage.current.click()}><CameraAltOutlined /></span>
        </span>
      </div>
      <div
        className="richeditorRoot"
        onClick={() => editorRef.current.focus()}
      >
        <Editor
          onFocus={handleFocus}
          blockStyleFn={getBlockStyle}
          editorState={editorState}
          customStylemap={STYLE_MAP}
          plugins={plugins}
          toolbar={{
            options: ["image"],
            image: {
              inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
              defaultSize: {
                height: "140px",
                width: "auto",
              },
            },
          }}
          handleKeyCommand={handleKeyCommand}
          onChange={(newEditorState) => setEditorState(newEditorState)}
          keyBindingFn={mapKeyToEditorCommand}
          ref={editorRef}
        />
      </div>
    </div>
  );
}

RichEditor.propTypes = {
  editorState: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  initialStyle: PropTypes.array
}

export default RichEditor;