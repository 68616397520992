/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
import { useHistory } from "react-router-dom";

const checkJwtAuth = async (setUserData, history) => {
  // You need to send token to your server to check token is valid
  // modify loginWithToken method in jwtService
  let user = await jwtAuthService.loginWithToken();
  if (user) setUserData(user);
  else
    history.push({
      pathname: "/signin"
    });
  return user;
};

const Auth = ({ children, setUserData }) => {
  const history = useHistory();
  setUserData(localStorageService.getItem("auth_user"));

  useEffect(() => {
    checkJwtAuth(setUserData, history);
  }, [setUserData]);

  return <Fragment>{children}</Fragment>;
};

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(mapStateToProps, { setUserData })(
  Auth
);
