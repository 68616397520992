import emailjs from '@emailjs/browser'
import { FRONT_URL } from 'config/environment';
import { SERVICE_ID, TEMPLATE, USER_ID } from '../config/emailkey'

const baseEmailSend = async (templateId, data) => {
    emailjs.send(SERVICE_ID, templateId, data, USER_ID).then(
        res => {
            console.info("Email sent:", res);
        },
        err => {
            console.error("Email failed:", err);
        }
    )
}

export const ApproveTagEmail = async (email, tag, name, isAdded) => {
    await baseEmailSend(
        isAdded ? TEMPLATE.SUGESTED_TAG_APPROVED_ADDED : TEMPLATE.SUGESTED_TAG_APPROVED,
        {
            to_address: email,
            tag: tag,
            name: name
        }
    )
}

export const ApproveGroupEmail = async (email, name, groupName, groupId, description, imageUrl) => {
    await baseEmailSend(
        TEMPLATE.REQUESTED_GROUP_APPROVED,
        {
            to_address: email,
            name: name,
            group_name: groupName,
            group_link: `${FRONT_URL}/discuss/convos/${groupId}`,
            description: description,
            image: imageUrl
        }
    )
}

export const ReferralCodeEmail = async (email, code) => {
    await baseEmailSend(
        TEMPLATE.REFERRAL_CODE_SEND,
        {
            to_address: email,
            referral_code: code,
        }
    )
}

export const sendTokenEmail = async (email, id, token) => {
    await baseEmailSend(
        TEMPLATE.EMAIL_TOKEN,
        {
            to_address: email,
            signup_link: `${process.env.REACT_APP_FRONT_BASE_URL}/signup-onboarding/${id}?type=pet-parent&EmailToken=${token}`,
        }
    )
}

export const DeletedPostEmail = async (email, name, groupName) => {
    await baseEmailSend(
        TEMPLATE.DELETED_POST,
        {
            to_address: email,
            first_name: name,
            group_name: groupName
        }
    )
}