import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { useStyles } from 'theme/style';

const TableHeader = (props) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {props.fields.map((item, idx) =>
          <TableCell className={classes.tableHead} align="left" key={idx} width={item.width}>{item.value || item}</TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}

TableHeader.propTypes = {
  fields: PropTypes.array.isRequired
}

export default TableHeader;