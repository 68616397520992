import React from 'react'
import PropTypes from 'prop-types';
import { convertImgUrl } from 'utils/string'

const KitImg = (props) => {
  return (
    <img {...props} src={convertImgUrl(props.src, props.kitwidth)} alt="" />
  )
}

KitImg.propTypes = {
  src: PropTypes.string.isRequired,
  kitwidth: PropTypes.number,
  className: PropTypes.string
}

export default KitImg;