import { BASE_API_URL, getHeader, getInstance } from "./basicService";

export const getPawffice = async () => {
  let res = [];
  await getInstance()
    .get(`${BASE_API_URL}/api/breederresource/pawfficehour`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch((err) => {
      console.error("Get pawffice error:", err);
    });
  return res;
};

export const addPawffice = async (body) => {
  let res = null;
  await getInstance()
    .post(`${BASE_API_URL}/api/admin/pawfficehour`, body, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch((err) => {
      console.error("Add pawffice error:", err);
    });
  return res;
};

export const deletePawffice = async (id) => {
  let res = null;
  await getInstance()
    .delete(`${BASE_API_URL}/api/admin/pawfficehour/${id}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch((err) => {
      console.error("Pawffice hour delete", err);
    });
  return res;
};
