import jwtAuthService from "../../services/jwtAuthService";
import { setUserData } from "./UserActions";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";

export function loginWithEmailAndPassword({ email, password, history }) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });

    jwtAuthService
      .loginWithEmailAndPassword(email, password)
      .then(user => {
        if(user.access_token) {
          dispatch(setUserData(user));
          history.push('/');
          return dispatch({
            type: LOGIN_SUCCESS
          });
        }
        else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: user
          })
        }
      })
      .catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}

export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD
    });
  };
}
