import { convertQuery } from "utils/string";

const { getInstance, getHeader, BASE_API_URL } = require("./basicService");

export const getComments = async (query) => {
  let res = [];
  await getInstance()
    .get(`${BASE_API_URL}/api/Comment${convertQuery(query)}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Comment get error:", err);
    });
  return res;
}

export const markReadComment = async (type, commentId) => {
  let res = 0;
  await getInstance()
    .post(`${BASE_API_URL}/api/comment/${commentId}/mark-read/${type}`, {}, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Comment get error:", err);
    });
  return res;
}

export const pinComment = async (commentId) => {
  let res = 0;
  await getInstance()
    .post(`${BASE_API_URL}/api/comment/${commentId}/pin`, {}, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Comment pin error:", err);
    });
  return res;
}

export const hideComment = async (type, commentId) => {
  let res = 0;
  await getInstance()
    .post(`${BASE_API_URL}/api/comment/${commentId}/hide/${type}`, {}, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Comment hide error:", err);
    });
  return res;
}

export const deleteComment = async (type, commentId) => {
  let res = 0;
  await getInstance()
    .post(`${BASE_API_URL}/api/comment/${commentId}/delete/${type}`, {}, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Comment delete error:", err);
    });
  return res;
}

export const getCommentReports = async (query) => {
  let res = [];
  await getInstance()
    .get(`${BASE_API_URL}/api/comment/reported${convertQuery(query)}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Comment report get error:", err);
    });
  return res;
}

export const getCommentDeletes = async (query) => {
  let res = [];
  await getInstance()
    .get(`${BASE_API_URL}/api/comment/deleted${convertQuery(query)}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Deleted comment get error:", err);
    })
  return res;
}