import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Pagination, Select, Stack } from '@material-ui/core';

const Paging = (props) => {
  const [pageSize, setPageSize] = useState(10);
  const handleSelectChange = (e) => {
    setPageSize(e.target.value);
    props.onPageSizeChange(e.target.value);
  }

  return (
    props.total
      ? <Stack direction="row" margin="10px">
        <Pagination
          count={Math.ceil(props.total / pageSize)}
          page={props.curPage}
          onChange={(e, page) => props.onPageChange(page)}
        />
        <Select
          size="small"
          autoWidth
          inputProps={{ 'aria-label': 'Without label' }}
          defaultValue={10}
          onChange={handleSelectChange}
        >
          <MenuItem value={5} defaultValue>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </Stack>
      : ""
  )
}

Paging.propTypes = {
  onPageSizeChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  total: PropTypes.number,
  curPage: PropTypes.number.isRequired
}

export default Paging;
