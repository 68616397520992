import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ManageUser from "./pages/ManageUser";
import ManageSubscribes from "./pages/ManageSubscribes";
import ManageArticles from "./pages/ManageArticles";
import ManagePawfficehours from "./pages/ManagePawfficehours";
import Layout from "./Layout";
import ManageImage from "./pages/MangeImage";
import EditImageCard from "./components/EditImageCard";
import MangeCommunityGroups from "pages/ManageCommunityGroups";
import ManageReferralCode from "pages/ManageReferralCodes";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import Signin from "./pages/Signin";
import Auth from "components/Auth";
import { Provider as StoreProvider } from "react-redux";
import { Store } from "./redux/Store";
import ManageSuggestedTags from "pages/ManageSuggestedTags";
import ManageComments from "pages/ManageComments";
import ManageReports from "pages/ManageReports";
import ManageSuggestedResources from "pages/ManageSuggestedResources";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StoreProvider store={Store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <Auth>
              <div className="App">
                <Switch>
                  <Route path="/signin">
                    <Signin />
                  </Route>
                  <Route path="/images">
                    <Layout>
                      <ManageImage />
                    </Layout>
                  </Route>
                  <Route path="/card">
                    <Layout>
                      <EditImageCard />
                    </Layout>
                  </Route>
                  <Route path="/community-groups">
                    <Layout>
                      <MangeCommunityGroups />
                    </Layout>
                  </Route>
                  <Route path="/suggested-tags">
                    <Layout>
                      <ManageSuggestedTags />
                    </Layout>
                  </Route>
                  <Route path="/comments">
                    <Layout>
                      <ManageComments />
                    </Layout>
                  </Route>
                  <Route path="/referral-codes">
                    <Layout>
                      <ManageReferralCode />
                    </Layout>
                  </Route>
                  <Route path="/subscribes">
                    <Layout>
                      <ManageSubscribes />
                    </Layout>
                  </Route>
                  <Route path="/articles">
                    <Layout>
                      <ManageArticles />
                    </Layout>
                  </Route>
                  <Route path="/pawffices">
                    <Layout>
                      <ManagePawfficehours />
                    </Layout>
                  </Route>
                  <Route path="/reports">
                    <Layout>
                      <ManageReports />
                    </Layout>
                  </Route>
                  <Route path="/suggested-resources">
                    <Layout>
                      <ManageSuggestedResources />
                    </Layout>
                  </Route>
                  <Route exact path="/">
                    <Layout>
                      <ManageUser />
                    </Layout>
                  </Route>
                </Switch>
              </div>
            </Auth>
          </Router>
        </LocalizationProvider>
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
