import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Close, Delete, DeleteForever, Description, Done, Home, Restore } from '@material-ui/icons'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Snackbar,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { a11yProps } from '../utils/mui';
import { v4 as uuidv4 } from 'uuid';
import {
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_DELETED,
  STATUS_ACTIVE,
  STATUS_NAMES,
  tableHeaders,
  STATUS_APPROVED,
} from "../config/constant";
import JsFileDownloader from 'js-file-downloader';
import TabPanel from "../components/TabPanel";
import { GridField } from "../components/GridField";
import { useStyles } from "../theme/style";
import KitImg from "components/kitImg";
import { createEmailToken, getUsers, restoreUser, showHomeUser, updateUserStatus } from "services/userService";
import Paging from "components/Paging";
import { downloadExcel } from "utils/download";
import { LoadingButton } from "@material-ui/lab";
import Alert from "components/Alert";
import TableHeader from "components/TableHeader";
import { sendTokenEmail } from "services/emailService";

export default function ManageUser() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [curStatus, setCurStatus] = useState(STATUS_ACTIVE);
  const [curPage, setCurPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [downloading, setDownloading] = useState(false);

  const handleDownload = async () => {
    setDownloading(true);
    const res = await getUsers(curStatus, {});
    if (res && res.total > 0) {
      downloadExcel(`${STATUS_NAMES[curStatus]} Users`,
        res.data.map(({Password, ...elem}) => {
          return {
            ...elem,
            Status: STATUS_NAMES[elem.Status],
            BreederBreedPrograms: elem.BreederBreedPrograms?.map(_ => _.ProgramName).join(','),
            BreederDamEnclouserDetails: elem.BreederDamEnclouserDetails?.map(_ => _.DamEnclosurePhotoVideoUrl).join(','),
            BreederPhotoGalleries: elem.BreederPhotoGalleries?.map(_ => _.PhotoUrl).join(','),
            BreederTags: elem.BreederTags?.map(_ => _.Name).join(','),
            GeneticTests: elem.GeneticTests?.map(_ => _.Name).join(','),
            HealthTests: elem.HealthTests?.map(_ => _.Name).join(','),
            FacebookGroups: elem.FacebookGroups?.map(_ => _.FacebookGroup).join(','),
            BreederEducationProgramDto: elem.BreederEducationProgramDto?.map(_ => _.ProgramName).join(','),
          }
        }));
    }
    setDownloading(false);
  }

  const handleClickHome = async (index) => {
    const res = await showHomeUser(users[index]?.Id);
    if (res) {
      setUsers(origin => origin.map((elem, idx) => (idx === index) ? { ...elem, ShowOnHome: !elem.ShowOnHome } : elem));
    }
    setIsLoading(false);
  }

  const handleClickDetails = (index) => {
    setSelectedUser(users[index]);
    setDialogOpen(true);
  };

  const handleClickAction = async (index, action) => {
    const user = users[index];
    setIsLoading(true);
    const res = await updateUserStatus(user?.Id, action);
    if (res) {
      setUsers(origin => { origin.splice(index, 1); return origin });
      if (action === STATUS_REJECTED) setMessage("Account rejected successfully!");
      if (action === STATUS_DELETED) setMessage("Account deleted successfully!");
      if (action === STATUS_APPROVED) {
        const token = uuidv4();
        const tokenResponse = await createEmailToken(token);
        if (tokenResponse) {
          await sendTokenEmail(user.Email, user.Id, token);
        };
      }
    }
    setIsLoading(false);
  };

  const handleClickRestore = async (index) => {
    const user = users[index];
    setIsLoading(true);
    const res = await restoreUser(user?.Id);
    if (res) {
      setUsers(origin => { origin.splice(index, 1); return origin; });
      setMessage("Account restored successfully!");
      setIsLoading(false);
    }
  };

  const handleDownloadImg = async (imgSrc) => {
    new JsFileDownloader({
      url: 'https://corsanywhere.herokuapp.com/' + imgSrc
    })
      .then(function () {
        // Called when download ended
      })
      .catch(function (error) {
        // Called when an error occurred
      });
  }

  const onPageChange = (page) => {
    setCurPage(page);
    (async function () {
      setIsLoading(true);
      const res = await getUsers(curStatus, { skip: (page - 1) * pageSize, take: pageSize });
      if (res) {
        setUsers(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const onRowsPerPageChange = (newSize) => {
    setCurPage(1);
    setPageSize(newSize);
    (async function () {
      setIsLoading(true);
      const res = await getUsers(curStatus, { skip: 0, take: newSize });
      if (res) {
        setUsers(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader fields={tableHeaders.users.fields} />
          <TableBody>
            {users.map(
              (user, index) =>
                user.Status === curStatus &&
                <TableRow
                  key={user.Id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    className={classes.tableBody}
                    component="th"
                    scope="row"
                  >
                    {index + 1 + (curPage - 1) * pageSize}
                  </TableCell>

                  <TableCell className={classes.tableBody} align="left">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <KitImg
                        src={user?.ProfilePicUrl || "/assets/user.png"}
                        kitwidth={100}
                        alt="user"
                        className={classes.userImg}
                      />
                      {user?.FirstName} {user?.MiddleName}{" "}
                      {user?.LastName}
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {user?.UserName}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {user?.Email}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {user?.Status
                      ? STATUS_NAMES[user?.Status]
                      : STATUS_NAMES[STATUS_PENDING]}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Detail">
                      <IconButton
                        aria-label="detail"
                        color="primary"
                        onClick={() => handleClickDetails(index)}
                      >
                        <Description />
                      </IconButton>
                    </Tooltip>
                    {curStatus === STATUS_ACTIVE &&
                      <Tooltip title={user.ShowOnHome ? "Hide on home" : "Show on home"}>
                        <IconButton
                          onClick={() => handleClickHome(index)}
                          color={user.ShowOnHome ? "success" : undefined}
                        >
                          <Home />
                        </IconButton>
                      </Tooltip>}

                    {curStatus === STATUS_PENDING ? (
                      <React.Fragment>
                        <Tooltip title="Approve">
                          <IconButton
                            onClick={() => handleClickAction(index, STATUS_APPROVED)}
                            color="success"
                          >
                            <Done />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Reject">
                          <IconButton
                            onClick={() => handleClickAction(index, STATUS_REJECTED)}
                            color="error"
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ) : user.Status === STATUS_DELETED ? (
                      <React.Fragment>
                        <Tooltip title="Restore">
                          <IconButton
                            onClick={() => handleClickRestore(index)}
                            color="success"
                          >
                            <Restore />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete Permanently">
                          <IconButton
                            onClick={() => handleClickAction(index, STATUS_DELETED)}
                            color="error"
                          >
                            <DeleteForever />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ) : (
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => handleClickAction(index, STATUS_DELETED)}
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
            )}
          </TableBody>
        </Table>

        <Snackbar
          open={false}
          autoHideDuration={6000}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </TableContainer>
    );
  };

  useEffect(() => {
    setCurPage(1);
    (async function () {
      setIsLoading(true);
      const res = await getUsers(curStatus, { skip: curPage - 1, take: pageSize });
      if (res) {
        setUsers(res.data);
        setTotal(res.total);
      } else {
        setUsers([]);
        setTotal(0);
      }
      setIsLoading(false);
    })(); // eslint-disable-next-line
  }, [curStatus])

  return (
    <LoadingOverlay active={isLoading} spinner={true} text="Loading...">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <LoadingButton
          loading={downloading}
          onClick={handleDownload}
          variant="contained"
          color="success"
        >Download</LoadingButton>
        <Grid item>Total: {total}</Grid>
      </Grid>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={(e, v) => setValue(v)} aria-label="basic tabs example">
          <Tab label="Active Users" {...a11yProps(0)} onClick={() => setCurStatus(STATUS_ACTIVE)} />
          <Tab label="Approved Users" {...a11yProps(4)} onClick={() => setCurStatus(STATUS_APPROVED)} />
          <Tab label="Pending Users" {...a11yProps(1)} onClick={() => setCurStatus(STATUS_PENDING)} />
          <Tab label="Rejected Users" {...a11yProps(2)} onClick={() => setCurStatus(STATUS_REJECTED)} />
          <Tab label="Deleted Users" {...a11yProps(3)} onClick={() => setCurStatus(STATUS_DELETED)} />
        </Tabs>
      </Box>
      <TabPanel >
        {renderTable()}
        <Paging
          onPageSizeChange={onRowsPerPageChange}
          onPageChange={onPageChange}
          total={total}
          curPage={curPage}
        />
      </TabPanel>
      <Dialog
        onClose={() => { setDialogOpen(false) }}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle id="customized-dialog-title" >
          User details ({selectedUser?.Email})
        </DialogTitle>
        <DialogContent dividers>
          <h2 className={classes.mainTitle}>About Yourself</h2>

          {selectedUser?.RefurralCode && <>
            <h3 className={classes.subTitle}>Referral Code</h3>
            <Grid container>
              <GridField title="Code" col={12}>{selectedUser?.RefurralCode}</GridField>
            </Grid>
          </>}

          {selectedUser?.HearAboutUs && <>
            <h3 className={classes.subTitle}>Hear About Us</h3>
            <Grid container>
              <GridField title="Description" col={12}>{selectedUser?.HearAboutUs}</GridField>
            </Grid>
          </>}

          <h3 className={classes.subTitle}>Personal Information</h3>
          <Grid container>
            <GridField title="Breeder Id">{selectedUser?.Id}</GridField>
            <GridField title="Name">
              {selectedUser?.FirstName} {selectedUser?.MiddleName}{" "}
              {selectedUser?.LastName}
            </GridField>
          </Grid>
          {selectedUser?.BusinessProgram &&
            <Grid container spacing={3}>
              <GridField title="Business / Program Name" col={12}>{selectedUser?.BusinessProgram}</GridField>
            </Grid>
          }
          <h3 className={classes.subTitle}>Your Location</h3>
          <Grid container spacing={3}>
            <GridField title="Address" col={12}>
              {selectedUser?.Address ? selectedUser?.Address + ', ' : ''} {selectedUser?.City ? selectedUser?.City + ', ' : ''}
              {selectedUser?.State ? selectedUser?.State + ', ' : ''} {selectedUser?.ZipCode},{" "}
              {selectedUser?.Country}
            </GridField>
          </Grid>

          <h2 className={classes.mainTitle}>About Your Program</h2>
          <h3 className={classes.subTitle}>Breeding Experience</h3>
          {selectedUser?.BreederBreedPrograms.length > 0 &&
            <Grid container spacing={3}>
              <GridField title="Breeds in program">{
                selectedUser?.BreederBreedPrograms.map((pro, idx) =>
                  <span key={idx}>{pro.ProgramName}{", "}</span>
                )}
              </GridField>
              <GridField title="Years of experience">{selectedUser?.YearsOfExperience}</GridField>
            </Grid>
          }
          <h3 className={classes.subTitle}>In your program</h3>
          <Grid container spacing={3}>
            <GridField title="How many dams do you have?">{selectedUser?.NumberOfDams}</GridField>
            <GridField title="How many studs do you have?">{selectedUser?.NumberOfStuds}</GridField>
          </Grid>
          <Grid container spacing={3}>
            <GridField title="Additional breeding-related information" col={12}>
              {selectedUser?.AdditionalInfo}
            </GridField>
          </Grid>
          <Grid container spacing={3}>
            <GridField title="Where do your dams live?" col={12}>{selectedUser?.DamsLocation}</GridField>
          </Grid>
          {selectedUser?.DamsSleepLocation &&
            <Grid container spacing={3}>
              <GridField title="For those kept on site, where do they sleep?" col={12}>
                {selectedUser?.DamsSleepLocation}
              </GridField>
            </Grid>
          }
          {selectedUser?.BreederDamEnclouserDetails.length > 0 &&
            <Grid container spacing={3}>
              <GridField title="" col={12}>
                {selectedUser?.BreederDamEnclouserDetails.map((item, idx) => <>
                  <div key={idx} className={classes.imgDownload}>
                    <embed src={item.DamEnclosurePhotoVideoUrl} key={idx} className={classes.photoGallery} download />
                    <Button
                      onClick={() => handleDownloadImg(item.DamEnclosurePhotoVideoUrl)}
                      color="warning"
                    >
                      Download
                    </Button>
                  </div>
                </>)}
              </GridField>
            </Grid>
          }
          <Grid container spacing={3}>
            <GridField title="Do you use the stud services of other breeders as part of your program?">
              {selectedUser?.IsUseStudServices ? 'Yes' : 'No'}
            </GridField>
            <GridField title="Do you sell stud services?">
              {selectedUser?.IsSellStudServices ? 'Yes' : 'No'}
            </GridField>
          </Grid>
          <h3 className={classes.subTitle}>What is the youngest age you will...</h3>
          <Grid container spacing={3}>
            <GridField title="Send a puppy home to new pet parents?">{selectedUser?.SendPuppyToNewParents}</GridField>
            <GridField title="Breed a new dam?">{selectedUser?.BreedNewDam}</GridField>
          </Grid>
          <h3 className={classes.subTitle}>How many times do you breed each dam...</h3>
          <Grid container spacing={3}>
            <GridField title="In an 18 month period?">{selectedUser?.DamBreedPeriod}</GridField>
            <GridField title="In her lifetime?">{selectedUser?.DamBreedLifetime}</GridField>
          </Grid>
          <h3 className={classes.subTitle}>What food do you feed your...</h3>
          <Grid container spacing={3}>
            <GridField title="Dams?">{selectedUser?.DamFood}</GridField>
            <GridField title="Puppies?">{selectedUser?.PuppiesFood}</GridField>
          </Grid>
          <h3 className={classes.subTitle}>Do you...</h3>
          <Grid container spacing={3}>
            <GridField title="Conduct health and/or genetic tests on your dogs?">
              {selectedUser?.IsHealthTest ? 'Yes' : 'No'}
            </GridField>
            <GridField title="Socialize your puppies before they are sent home?">
              {selectedUser?.IsSocializePuppies ? 'Yes' : 'No'}
            </GridField>
          </Grid>
          {(selectedUser?.GeneticTests.length > 0 || selectedUser?.SocializeDescription) &&
            <Grid container spacing={3}>
              {selectedUser?.GeneticTests.length > 0 &&
                <GridField title="What genetic tests do you conduct?">
                  {selectedUser?.GeneticTests.map((item, idx) =>
                    <span key={idx}>{item.Name}{", "}</span>
                  )}
                </GridField>
              }
              {(selectedUser?.SocializeDescription &&
                <GridField title="Description">
                  {selectedUser?.SocializeDescription}
                </GridField>
              )}
            </Grid>
          }
          {selectedUser?.HealthTests.length > 0 &&
            <GridField title="What health tests do you conduct?">
              {selectedUser?.HealthTests.map((item, idx) =>
                <span key={idx}>{item.Name}{", "}</span>
              )}
            </GridField>
          }
          <Grid container spacing={3}>
            <GridField title="Do you vaccinate puppies before they go home?">
              {selectedUser?.IsVaccinatePuppies ? 'Yes' : 'No'}
            </GridField>
            <GridField title="Do you have a reproductive veterinarian?">
              {selectedUser?.IsReproductiveVeterinarian ? 'Yes' : 'No'}
            </GridField>
          </Grid>
          {(selectedUser?.VaccinationDescription || selectedUser?.VetName) &&
            <Grid container spacing={3}>
              {selectedUser?.VaccinationDescription &&
                <GridField title="Description">
                  {selectedUser?.VaccinationDescription}
                </GridField>
              }
              <GridField title="Name and contact info">
                {selectedUser?.VetName}{", "}
                {selectedUser?.VetEmail}{", "}
                {selectedUser?.VetPhoneNumber}{", "}
              </GridField>
            </Grid>
          }
          <h2 className={classes.mainTitle}>About Your Business</h2>
          <Grid container spacing={3}>
            <GridField title="Which best describes your litter availability?">{selectedUser?.LitterAvailability}</GridField>
            <GridField title="Approximately how many puppies do you sell annually?">{selectedUser?.AnnualSellPuppies}</GridField>
          </Grid>
          <Grid container spacing={3}>
            <GridField col={12} title="Do you share information (photos, health records) about the dam and stud with prospective pet parents?">
              {selectedUser?.IsShareInfoWithParents ? 'Yes' : 'No'}
            </GridField>
          </Grid>
          <Grid container spacing={3}>
            <GridField col={12} title="Have ever sold a puppy to a third-party vendor, such as a pet store?">
              {selectedUser?.IsSellPuppyToThirdParty ? 'Yes' : 'No'}
            </GridField>
          </Grid>
          {selectedUser?.ThirdPartyName &&
            <Grid container spacing={3}>
              <GridField col={12} title="Fill Name">
                {selectedUser?.ThirdPartyName}
              </GridField>
            </Grid>
          }
          <h3 className={classes.subTitle}>Do you typically ask pet parents to...</h3>
          <Grid container spacing={3}>
            <GridField title="Fill out an application for a puppy?">
              {selectedUser?.IsFillApplication ? 'Yes' : 'No'}
            </GridField>
            <GridField title="Sign a contract to purchase a puppy?">
              {selectedUser?.IsContractSigned ? 'Yes' : 'No'}
            </GridField>
          </Grid>
          <Grid container spacing={3}>
            <GridField title="Pick puppies up from your home?">
              {selectedUser?.IsPickPuppyFromHome ? 'Yes' : 'No'}
            </GridField>
            <GridField title="Stay in touch for questions over time?">
              {selectedUser?.IsStayInTouch ? 'Yes' : 'No'}
            </GridField>
          </Grid>
          {selectedUser?.ProgramDetails &&
            <Grid container spacing={3}>
              <GridField title="Is there anything else about your program that you would like for us to know?" col={12}>
                {selectedUser?.ProgramDetails}
              </GridField>
            </Grid>
          }
          {(selectedUser?.IsCompletedBreederEducation || selectedUser?.IsBelongToClub
            || selectedUser?.BreedingProgramWebsite || selectedUser?.FacebookUrl) &&
            <h2 className={classes.mainTitle}>How You Currently Learn &amp; Connect</h2>
          }
          {selectedUser?.IsCompletedBreederEducation && <>
            <h3 className={classes.subTitle}>Have you completed any...</h3>
            <Grid container spacing={3}>
              <GridField col={12} title="Breeder educational programs (i.e. Puppy Culture, Midwoofery, Avidog, AKC Canine College, etc.)?">
                {selectedUser?.IsCompletedBreederEducation ? 'Yes' : 'No'}
              </GridField>
            </Grid>
          </>}
          {selectedUser?.EducationPrograms.length > 0 &&
            <Grid container spacing={3}>
              <GridField title="Names separated by commas" col={12}>
                {selectedUser?.EducationPrograms.map((item, idx) =>
                  <span key={idx}>{item.ProgramName}{", "}</span>
                )}
              </GridField>
            </Grid>
          }
          {selectedUser?.IsBelongToClub && <>
            <h3 className={classes.subTitle}>Do you belong to any...</h3>
            <Grid container spacing={3}>
              <GridField col={12} title="Breeding clubs or organizations?">
                {selectedUser?.IsBelongToClub ? 'Yes' : 'No'}
              </GridField>
            </Grid>
          </>}
          {selectedUser?.BreederClubs.length > 0 &&
            <Grid container spacing={3}>
              <GridField title="Clubs or Organizations" col={12}>
                {selectedUser?.BreederClubs.map((item, idx) =>
                  <span key={idx}>{item.Name}{", "}</span>
                )}
              </GridField>
            </Grid>
          }
          {selectedUser?.BreedingProgramWebsite && <>
            <h3 className={classes.subTitle}>What is your...</h3>
            <Grid container spacing={3}>
              <GridField col={12} title="Breeding program website?">
                {selectedUser?.BreedingProgramWebsite}
              </GridField>
            </Grid>
          </>}
          {(selectedUser?.FacebookUrl || selectedUser?.InstagramUrl
            || selectedUser?.TwitterUrl || selectedUser?.YoutubeUrl || selectedUser?.TikTokUrl) &&
            <h3 className={classes.subTitle}>What Social Media do you use?</h3>
          }
          {selectedUser?.FacebookUrl &&
            <Grid container spacing={3}>
              <GridField col={12} title="Facebook">
                {selectedUser?.FacebookUrl}
              </GridField>
            </Grid>
          }
          {selectedUser?.FacebookGroups.length > 0 &&
            <Grid container spacing={3}>
              <GridField col={12} title="What Breeder Groups Do You Participate in on Facebook?">
                {selectedUser?.FacebookGroups.map((item, idx) =>
                  <span key={idx}>{item.FacebookGroup}{","}</span>
                )}
              </GridField>
            </Grid>
          }
          {(selectedUser?.InstagramUrl || selectedUser?.TwitterUrl) &&
            <Grid container spacing={3}>
              {selectedUser?.InstagramUrl &&
                <GridField title="Instagram">
                  {selectedUser?.InstagramUrl}
                </GridField>
              }
              {selectedUser?.TwitterUrl &&
                <GridField title="Twitter">
                  {selectedUser?.TwitterUrl}
                </GridField>
              }
            </Grid>
          }
          {(selectedUser?.YoutubeUrl || selectedUser?.TikTokUrl) &&
            <Grid container spacing={3}>
              {selectedUser?.YoutubeUrl &&
                <GridField title="Youtube">
                  {selectedUser?.YoutubeUrl}
                </GridField>
              }
              {selectedUser?.TikTokUrl &&
                <GridField title="TikTok">
                  {selectedUser?.TikTokUrl}
                </GridField>
              }
            </Grid>
          }

          {selectedUser?.UserName && <>
            <h2 className={classes.mainTitle}>Your Account</h2>
            <h3 className={classes.subTitle}>Confirm Username</h3>
            <Grid container spacing={3}>
              <GridField col={12} title="Username">{selectedUser?.UserName}</GridField>
            </Grid>
            {selectedUser?.ProgramPhotoVideoUrl && <>
              <h3 className={classes.subTitle}>Program Picture</h3>
              <Grid container spacing={3}>
                <GridField col={12} title="" className={classes.flex}>
                  <div className={classes.imgDownload}>
                    <KitImg src={selectedUser?.ProgramPhotoVideoUrl} kitwidth={200} className={classes.photoGallery} alt="" download />
                    <Button
                      onClick={() => handleDownloadImg(selectedUser?.ProgramPhotoVideoUrl)}
                      color="warning"
                    >
                      Download
                    </Button>
                  </div>
                </GridField>
              </Grid>
            </>}

            {
              selectedUser?.TShirtSize && <>
                <Grid container spacing={3}>
                  <GridField col={12} title="T-shirt size">{selectedUser?.TShirtSize}</GridField>
                </Grid>
              </>
            }
          </>}
          {(selectedUser?.BusinessDetails || selectedUser?.ResponsibleBreedingDescription
            || selectedUser?.BreederTags.length > 0) && <>
              <h2 className={classes.mainTitle}>Your Profile</h2>
              <h3 className={classes.subTitle}>About Me and My Program...</h3>
              {selectedUser?.ShortDescription &&
                <Grid container spacing={3}>
                  <GridField col={12} title="A very short description of me and my program(max 140 characters)">
                    {selectedUser?.ShortDescription}
                  </GridField>
                </Grid>
              }
              {selectedUser?.BusinessDetails &&
                <Grid container spacing={3}>
                  <GridField col={12} title="Aim for 2-3 paragraphs explaning what you do and your business">
                    {selectedUser?.BusinessDetails}
                  </GridField>
                </Grid>
              }
              {selectedUser?.ResponsibleBreedingDescription &&
                <Grid container spacing={3}>
                  <GridField col={12} title="I believe responsible breeding means...">
                    {selectedUser?.ResponsibleBreedingDescription}
                  </GridField>
                </Grid>
              }
              {selectedUser?.BreederTags.length > 0 && <>
                <h3 className={classes.subTitle}>I am Knowledgeable About...</h3>
                <Grid container spacing={3}>
                  <GridField col={12} title="Add up to 4 tags to help other breeders find you (Ex: photography, genetics)">
                    {selectedUser?.BreederTags.map((item, idx) =>
                      <span key={idx}>{item.Name}{", "}</span>
                    )}
                  </GridField>
                </Grid>
              </>}
            </>}
          {selectedUser?.BreederPhotoGalleries.length > 0 && <>
            <h2 className={classes.mainTitle}>Photos Gallery</h2>
            <h3 className={classes.subTitle}>Gallery</h3>
            <Grid container spacing={3}>
              <GridField col={12} title="">
                {selectedUser?.BreederPhotoGalleries.map((item, idx) => <>
                  <div className={classes.imgDownload}>
                    <KitImg src={item.PhotoUrl} kitwidth={200} className={classes.photoGallery} alt="" key={idx} />
                    <Button
                      onClick={() => handleDownloadImg(item.PhotoUrl)}
                      color="warning"
                    >
                      Download
                    </Button>
                  </div>
                </>)}
              </GridField>
            </Grid>
          </>}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingOverlay>
  );
}
