import axios from "axios";

export const callApiWithToken = async (accessToken, method, url, body) => {
    let headers = {};

    if(accessToken) {
        const bearer = `Bearer ${accessToken}`;
        headers["Authorization"]  = bearer;
    }
    
    return axios({
        method: method,
        url: url,
        headers: headers,
        data: body
    });
}