import { getHeader, getInstance } from "./basicService";

export const getTags = async function () {
  let res = null;
  await getInstance()
    .get(`${process.env.REACT_APP_API_BASE_URL}/api/admin/knowledge-tag`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Tags get error:", err);
    })
  return res;
}

export const updateTagStatus = async function (id, value) {
  let res = null;
  await getInstance()
    .put(`${process.env.REACT_APP_API_BASE_URL}/api/admin/knowledge-tag/${id}/status/${value}`, {}, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Tags get error:", err);
    })
  return res;
}

