import React, { useEffect, useRef, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Snackbar,
  Button,
  Dialog,
  Box,
  Select,
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextareaAutosize,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { tableHeaders } from "../config/constant";
import InputBase from "@material-ui/core/InputBase";
import { styled } from "@material-ui/styles";
import { useStyles } from "../theme/style";
import { GridField } from "../components/GridField";
import AWSUploadFile from "components/FileUploadService";
import RichEditor from "components/RichEditor";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import KitImg from "components/kitImg";
import Alert from "components/Alert";
import TableHeader from "components/TableHeader";
import { addArticle, deleteArticle, getArticles, showHomeArticle, updateArticle } from "services/resourceService";
import { getUsers } from "services/userService";
import Paging from "components/Paging";
import { downloadExcel } from "utils/download";
import { LoadingButton } from "@material-ui/lab";
import { Delete, Description, Edit, Home } from "@material-ui/icons";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#AFB3B6",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 4),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    backgroundColor: "#F5F9FC",
    [theme.breakpoints.up("md")]: {
      width: "25ch",
    },
  },
}));

const options = {
  entityStyleFn: (entity) => {
    const entityType = entity.get("type").toLowerCase();
    if (entityType === "draft-js-video-plugin-video") {
      const data = entity.getData();
      return {
        element: "video",
        attributes: {
          src: data.src,
        },
      };
    }
    return null;
  },
};

export default function ManageArticles() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState('no');
  const [curImage, setCurImage] = useState('');
  const [selIndex, setSelIndex] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [contentState, setContentState] = useState(EditorState.createEmpty());
  const [sourceState, setSourceState] = useState(EditorState.createEmpty());
  const [curPage, setCurPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [downloading, setDownloading] = useState(false);
  const [articleTags, setArticleTags] = useState([{
    ArticleId: 0,
    Name: ''
  }]);
  const fileInput = useRef(null);

  const onFileUploadBtnClick = () => {
    fileInput.current.click();
  }

  const [inputData, setInputData] = useState({});

  useEffect(() => {
    setCurPage(1);
    (async function () {
      setIsLoading(true)
      const res = await getArticles({ skip: curPage - 1, take: pageSize });
      if (res && res.data?.length > 0) {
        setArticles(res.data);
        setTotal(res.total);
      } else {
        setArticles([]);
        setTotal(0);
      }

      const userResponse = await getUsers(1);
      if (userResponse && userResponse.data?.length > 0) {
        setUsers(userResponse.data);
      }
      setIsLoading(false);
    })(); // eslint-disable-next-line
  }, []);

  const onDeleteArticleTag = (index) => {
    if (articleTags.length < 2) return false;
    let tmpArticleTags = articleTags;
    tmpArticleTags.splice(index, 1);
    setArticleTags([...tmpArticleTags]);
    setNewArticleData('ArticleTags', tmpArticleTags);
  }

  const handleClickHome = async (index) => {
    const res = await showHomeArticle(articles[index]?.Id);
    if (res) {
      setArticles(origin => origin.map((elem, idx) => (idx === index) ? { ...elem, ShowOnHome: !elem.ShowOnHome } : elem));
    }
    setIsLoading(false);
  }

  const getAutherName = (id) => {
    const curUser = users.filter(user => user.Id === id);
    let midName = curUser[0]?.MiddleName;
    if (midName == null)
      midName = '';
    return curUser[0]?.FirstName + midName + ' ' + curUser[0]?.LastName;
  }

  const onAddArticleTag = () => {
    const selId = isEdit ? selectedArticle.Id : 0;
    const blankArticleTag = { ArticleId: selId, Name: '' };
    setArticleTags([...articleTags, blankArticleTag]);
  }

  const handleClickDelete = (index) => {
    setConfirmDialogOpen(true);
    setSelIndex(index);
  }

  const handleDeleteArticle = async () => {
    if (isConfirmDelete === 'yes') {
      setIsLoading(true);
      const res = await deleteArticle(articles[selIndex]?.Id);
      if (res) {
        setArticles(origin => { origin.splice(selIndex, 1); return origin });
        setIsConfirmDelete('no');
      }
    }
    setIsLoading(false);
    setConfirmDialogOpen(false);
  }

  const handleClickAddAction = async () => {
    if (inputData.Title !== '' && inputData.Image !== '') {
      setIsLoading(true);
      const res = await addArticle({
        ...inputData,
        Content: stateToHTML(contentState.getCurrentContent(), options),
        Source: stateToHTML(sourceState.getCurrentContent(), options),
      });
      if (res) {
        setArticles(origin => { origin.push(res.data); return origin });
        setInputData({});
        setMessage("Added an article successfully!");
      }
      setIsLoading(false);
      setAddDialogOpen(false);
    }
  };

  const handleClickEditAction = async () => {
    setIsLoading(true);
    const res = await updateArticle({
      ...inputData,
      Content: stateToHTML(contentState.getCurrentContent(), options),
      Source: stateToHTML(sourceState.getCurrentContent(), options)
    });
    if (res) {
      setArticles(origin => origin.map((elem, idx) => (idx === selIndex) ? res.data : elem));
      setInputData({});
      setMessage("Edited an article successfully!");
    }
    setIsLoading(false);
    setAddDialogOpen(false);
  }

  const handleClickDetails = (index) => {
    setSelectedArticle(articles[index]);
    setDialogOpen(true);
  };

  const handleClickEdit = (index) => {
    setSelectedArticle(articles[index]);
    const contentBlockFromHtml = convertFromHTML(articles[index]?.Content || "");
    setContentState(EditorState.createWithContent(ContentState.createFromBlockArray(
      contentBlockFromHtml.contentBlocks,
      contentBlockFromHtml.entityMap
    )));
    const sourceBlockFromHtml = convertFromHTML(articles[index]?.Source || "");
    setSourceState(EditorState.createWithContent(ContentState.createFromBlockArray(
      sourceBlockFromHtml.contentBlocks,
      sourceBlockFromHtml.entityMap
    )));

    setIsEdit(true);
    setSelIndex(index);
    const curInput = {
      Id: articles[index].Id,
      Title: articles[index].Title,
      Description: articles[index].Description,
      Content: articles[index].Content,
      AutherName: '',
      CreatedById: articles[index].CreatedBy.Id,
      Source: articles[index].Source,
      ArticleTags: articles[index].ArticleTags,
      Image: articles[index].Image,
      CreatedDate: articles[index].CreatedDate
    };
    setCurImage(articles[index].Image);
    setArticleTags([]);
    let tmpArticleTags = articles[index].ArticleTags;
    const blankArticleTag = { ArticleId: articles[index].Id, Name: '' };
    if (tmpArticleTags.length < 1)
      setArticleTags([...tmpArticleTags, blankArticleTag]);
    else
      setArticleTags([...tmpArticleTags]);
    setInputData(curInput);
    setAddDialogOpen(true);
  }

  const handleClickBrowse = (e) => {
    const file = e.currentTarget.files[0];
    setUploading(true);
    getFileURL(file);
  };

  const getFileURL = async (image) => {
    const res = await AWSUploadFile(image, `images/profiles`);
    if (res.location) {
      setUploading(false);
      setCurImage(res.location);
      setNewArticleData('Image', res.location);
    }
  };

  const onSetArticleTag = (idx, value) => {
    const selId = isEdit ? selectedArticle.Id : 0;
    const newData = { ArticleId: selId, Name: value }
    let tmpArticleTags = articleTags;
    tmpArticleTags[idx] = newData;
    setArticleTags([...tmpArticleTags]);
    setNewArticleData('ArticleTags', tmpArticleTags);
  }

  const setNewArticleData = (key, value) => {
    let tmpInput = {
      ...inputData,
      [key]: value,
    };
    setInputData(tmpInput);
  }

  const onAddNewArticle = () => {
    setCurImage('');
    setContentState(EditorState.createEmpty());
    const sourceBlockFromHtml = convertFromHTML("<i>&nbsp;</i>");
    let state = EditorState.createWithContent(ContentState.createFromBlockArray(
      sourceBlockFromHtml.contentBlocks,
      sourceBlockFromHtml.entityMap
    ));
    setSourceState(state);
    const blankArticleTag = { ArticleId: 0, Name: '' };
    setArticleTags([blankArticleTag]);
    setInputData({});
    setAddDialogOpen(true);
    setIsEdit(false);
  }

  const onPageChange = (page) => {
    setCurPage(page);
    (async function () {
      setIsLoading(true);
      const res = await getArticles({ skip: (page - 1) * pageSize, take: pageSize });
      if (res) {
        setArticles(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const onRowsPerPageChange = (newSize) => {
    setCurPage(1);
    setPageSize(newSize);
    (async function () {
      setIsLoading(true);
      const res = await getArticles({ skip: 0, take: newSize });
      if (res) {
        setArticles(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const handleDownload = async () => {
    setDownloading(true);
    const res = await getArticles({});
    if (res && res.total > 0) {
      downloadExcel('Articles', res.data);
    }
    setDownloading(false);
  }

  return (
    <LoadingOverlay active={isLoading} spinner={true} text="Loading...">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <LoadingButton
          loading={downloading}
          onClick={handleDownload}
          variant="contained"
          color="success"
        >Download</LoadingButton>
        <Button color="primary" onClick={() => onAddNewArticle()} className={classes.addNewCode}>Input new article</Button>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader fields={tableHeaders.articles.fields} />
          <TableBody>
            {articles?.map(
              (item, index) =>
                item && (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      className={classes.tableBody}
                      component="th"
                      scope="row"
                    >
                      {index + 1 + (curPage - 1) * pageSize}
                    </TableCell>
                    <TableCell
                      className={classes.tableBody}
                      component="th"
                      scope="row"
                    >
                      <KitImg
                        src={item.Image}
                        kitwidth={100}
                        className={classes.rounded} />
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">{item?.Title}</TableCell>
                    <TableCell className={classes.tableBody} align="left">{item?.AutherName || (item?.CreatedBy && getAutherName(item?.CreatedBy.Id))}</TableCell>
                    <TableCell className={classes.tableBody} align="left">{moment(item?.CreatedDate).format("LL")}</TableCell>
                    <TableCell>
                      <Tooltip title="Detail">
                        <IconButton
                          color="primary"
                          onClick={() => handleClickDetails(index)}
                        >
                          <Description />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={item.ShowOnHome ? "Hide on home" : "Show on home"}>
                        <IconButton
                          onClick={() => handleClickHome(index)}
                          color={item.ShowOnHome ? "success" : undefined}
                        >
                          <Home />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton
                          color="warning"
                          onClick={() => handleClickEdit(index)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          onClick={() => handleClickDelete(index)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>

        </Table>

        <Snackbar
          open={false}
          autoHideDuration={6000}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </TableContainer>
      <Paging
        onPageSizeChange={onRowsPerPageChange}
        onPageChange={onPageChange}
        total={total}
        curPage={curPage}
      />
      <Dialog
        onClose={() => setDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle id="customized-dialog-title">
          Article details (ID: {selectedArticle?.Id})
        </DialogTitle>
        <DialogContent dividers>
          <GridField title="Title" col={12}>{selectedArticle?.Title}</GridField>
          <Grid container spacing={3}>
            <GridField title="Author" col={6}>{selectedArticle?.CreatedById}</GridField>
            <GridField title="Author Image" col={6}>
              <KitImg
                src={selectedArticle?.Image}
                kitwidth={100}
                alt="article"
                width="100px"
                height="100px"
                className={classes.rounded} />
            </GridField>
          </Grid>
          {selectedArticle?.Description &&
            <GridField title="Description" isHtml={true} col={12}>{selectedArticle?.Description}</GridField>
          }
          {selectedArticle?.Content &&
            <GridField title="Content" isHtml={true} col={12}>{selectedArticle?.Content}</GridField>
          }
          {selectedArticle?.Source &&
            <GridField title="Source" isHtml={true} col={12}>{selectedArticle?.Source}</GridField>
          }
          {selectedArticle?.CreatedBy &&
            <Grid container spacing={3}>
              <GridField title="Author" col={6}>
                <strong>Name: </strong>{selectedArticle?.CreatedBy.Name}
              </GridField>
              <GridField title="Profile Image" col={6}>
                <KitImg
                  src={selectedArticle?.CreatedBy.ProfileImage}
                  kitwidth={100}
                  alt="article"
                  width="40px"
                  height="40px"
                  className={classes.userImg} />
              </GridField>
            </Grid>
          }
          {selectedArticle?.ArticleTags.length > 0 &&
            <GridField title="Article Tags" col={12}>
              {selectedArticle?.ArticleTags.map((item, idx) =>
                <span key={idx}>{item.Name}</span>
              )}
            </GridField>
          }
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => setDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={addDialogOpen}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle id="customized-dialog-title">
          {isEdit ? 'Edit Article' : 'Add New Article'}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <GridField col={12} title="Title">
              <StyledInputBase
                value={inputData.Title}
                placeholder="Title"
                onChange={({ target: { value } }) => {
                  setNewArticleData('Title', value);
                }}
              />
            </GridField>
            <GridField col={6} title="Custom Author">
              <StyledInputBase
                value={inputData.AutherName}
                placeholder="Type AutherName"
                onChange={({ target: { value } }) => {
                  setNewArticleData('AutherName', value);
                }}
              />
            </GridField>
            <GridField col={6} title="Telltail Author">
              <Select id="auther"
                value={inputData.CreatedById}
                defaultChecked
                onChange={({ target: { value } }) => {
                  setNewArticleData('CreatedById', value);
                }
                } style={{ width: '100%' }}>
                {users.map((user, idx) =>
                  <MenuItem key={idx} value={user.Id}>{user?.FirstName} {user?.MiddleName}{" "}
                    {user?.LastName}</MenuItem>
                )}
              </Select>
            </GridField>
          </Grid>
          <Grid container>
            <GridField col={12} title="Description">
              <TextareaAutosize
                aria-label="Description"
                value={inputData.Description}
                placeholder="Description"
                style={{ width: '100%', height: 150 }}
                onChange={(e) => setNewArticleData('Description', e.target.value)}
              />
            </GridField>
          </Grid>
          <Grid container>
            <GridField col={12} title="Content" />
            <RichEditor editorState={contentState} setEditorState={(newEditorState) => setContentState(newEditorState)} />
          </Grid>
          <Grid container>
            <GridField col={12} title="Source">
              <RichEditor editorState={sourceState} setEditorState={(newEditorState) => setSourceState(newEditorState)} initialStyle={["ITALIC"]} />
            </GridField>
          </Grid>
          <Grid container>
            <GridField col={12} title="Image">
              <input
                type="file"
                id="imageFile"
                ref={fileInput}
                style={{ display: 'none' }}
                onChange={handleClickBrowse}
                onKeyUp={handleClickBrowse}
                accept="image/*"
              />
              {isEdit ? (
                <Button color="warning" onClick={onFileUploadBtnClick}>Edit Image</Button>
              ) : (
                <Button color="warning" onClick={onFileUploadBtnClick}>Add New Image</Button>
              )}
              {uploading ? (<img src="/assets/loading.gif" width="30px" alt="uploading" />) : null}
            </GridField>
          </Grid>
          {curImage && (
            <Grid container>
              <KitImg src={curImage} kitwidth={100} alt="article" className={classes.rounded} />
            </Grid>
          )}
          <Grid container>
            <GridField col={12} title="Article Tags">
              {articleTags.map((tag, idx) =>
                <div key={idx}>
                  <StyledInputBase
                    value={tag.Name}
                    placeholder="Tag Name"
                    onChange={(e) => onSetArticleTag(idx, e.target?.value)}
                  />
                  <Button color="error" onClick={() => onDeleteArticleTag(idx)}>Delete</Button>
                  &nbsp;
                  {articleTags.length - 1 === idx &&
                    <Button color="warning" onClick={() => onAddArticleTag()}>Add New</Button>
                  }
                </div>
              )}
            </GridField>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isEdit ? (
            <Button
              autoFocus
              onClick={() => handleClickEditAction()}
              color="primary"
              disabled={uploading}
              className={classes.sendBtn}
            >
              Save Changes
            </Button>
          ) : (
            <Button
              autoFocus
              onClick={() => handleClickAddAction()}
              color="primary"
              disabled={uploading}
              className={classes.sendBtn}
            >
              Add
            </Button>
          )}
          <Button
            onClick={() => setAddDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={confirmDialogOpen}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle id="customized-dialog-title">
          Are you sure you want to delete this article?
          <Button className={classes.closeButton} onClick={() => setConfirmDialogOpen(false)}>✕</Button>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <RadioGroup
              row
              value={isConfirmDelete}
              style={{ width: '100%' }}
              aria-labelledby="confirm-radio-button"
              name="row-radio-buttons-group">
              <GridField col={6}>
                <FormControlLabel value="yes" control={<Radio onClick={() => setIsConfirmDelete('yes')} />} label="YES" />
              </GridField>
              <GridField col={6}>
                <FormControlLabel value="no" control={<Radio onClick={() => setIsConfirmDelete('no')} />} label="NO" />
              </GridField>
            </RadioGroup>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="primary"
            onClick={handleDeleteArticle}
            className={classes.sendBtn}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingOverlay>
  );
}