export const STATUS_PENDING = 0;
export const STATUS_ACTIVE = 1;
export const STATUS_REJECTED = 2;
export const STATUS_DELETED = 3;
export const STATUS_APPROVED = 4;
export const STATUS_READ = 5;
export const STATUS_UNREAD = 6;
export const STATUS_NAMES = [
  "Pending",
  "Active",
  "Rejected",
  "Deleted",
  "Approved",
  "Read",
  "Unread",
];

export const USER_MANAGE_ACTION_REJECT = "action_reject";
export const USER_MANAGE_ACTION_APPROVE = "action_approve";

export const REFERRAL_CODE_SENT = "Referral Code Sent Already";
export const REFERRAL_CODE_NOT_SENT = "Referral Code not sent yet";

export const tableHeaders = {
  users: {
    fields: [
      { value: "No", width: "10px" },
      "Name",
      "UserName",
      "Email",
      "Status",
      { value: "Action", width: "120px" },
    ],
  },
  community: {
    fields: [
      { value: "No", width: "10px" },
      { value: "Name", width: "300px" },
      "Description",
      "Contents",
      { value: "Status", width: "60px" },
      "Category",
      { value: "Action", width: "200px" },
    ],
  },
  subscribe: {
    fields: [
      { value: "No", width: "10px" },
      "Email Address",
      "Submitted Time",
      { value: "Action", width: "30px" },
    ],
  },
  articles: {
    fields: [
      { value: "No", width: "10px" },
      { value: "Image", width: "100px" },
      "Title",
      "Author",
      "Created Date",
      { value: "Action", width: "160px" },
    ],
  },
  pawfficehour: {
    fields: [
      { value: "No", width: "10px" },
      "Title",
      "Event Date",
      "Available Spots",
      "Total Spots",
      "Next PawfficeId",
      { value: "Action", width: "80px" },
    ],
  },
  SuggestTag: {
    fields: [
      { value: "No", width: "10px" },
      "Suggested",
      "Suggested By",
      "Suggested Date",
      "Updated Date",
      "Status",
      { value: "Action", width: "80px" },
    ],
  },
  SuggestResource: {
    fields: [
      { value: "No", width: "10px" },
      "Type",
      "Suggested By",
      "Description",
      "Link",
      "Suggested Date",
      "Status",
      { value: "Action", width: "30px" },
    ],
  },
  comment: {
    fields: [
      { value: "No", width: "5px" },
      "Type",
      "Author",
      "Location",
      "Preview of content",
      "Link",
      "Time stamp",
      { value: "Action", width: "230px" },
    ],
  },
  report: {
    fields: [
      { value: "No", width: "5px" },
      "Type",
      "ReportedBy",
      "Location",
      "Reason",
      "Addtional Info",
      "Reported Date",
      { value: "Action", width: "30px" },
    ],
  },
  codes: {
    fields: [
      "No",
      "ReferralCode",
      "UsedCount",
      "Status",
      { value: "Action", width: "120px" },
    ],
  },
  captured: {
    fields: [
      "No",
      "Email",
      "Status",
      "Referral Code",
      { value: "Action", width: "150px" },
    ],
  },
};
