import React, { useState } from 'react';
import { makeStyles, styled } from '@material-ui/styles';
import BasicDropzone from './BasicDropzone';
// import { callApiWithToken } from "../services/apiCallService";

import { Box, TextareaAutosize, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

    fileUploadCard: {
        background: '#FFFFFF',
        border: '3px dashed #e3e4e5',
        boxSizing: 'border-box',
        borderRadius: '28px',
        height: '193px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overFlow: 'hidden',
        boxShadow: 'none !important',
        [theme.breakpoints.down('md')]: {
            borderRadius: '10px',
        }
    },

    fileUploadBtn: {
        background: '#F5F9FC !important',
        borderRadius: '80px !important',
        opacity: '100% !important'
    },

    addNoteInput: {
        background: '#FFFFFF',
        border: '1.5px solid #ebebeb',
        boxSizing: 'border-box',
        borderRadius: '17px',
        width: '100%',
        margin: '32px 0',
        padding: '19px 21px',
        maxWidth: '100%',
        '&:focus': {
            outline: 'unset',
        },
        [theme.breakpoints.down('md')]: {
            borderRadius: '10px',
        }

    },
    btnGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '12px',
        flexWrap: 'wrap',

        [theme.breakpoints.down('md')]: {
            '& button': {
                marginTop: '10px !important',
                width: '100%'
            },
        }
    }
}));
const CancelButton = styled(Button)(({ theme }) => ({
    color: '#CC7191 !important',
    backgroundColor: '#faf1f4 !important',
    margin: 'auto 16px !important',
    boxShadow: 'none !important',
    '&:hover': {
        backgroundColor: '#CC7191 !important',
        color: '#faf1f4 !important'
    },
}));

const AddImageButton = styled(Button)(({ theme }) => ({
    color: '#faf1f4 !important',
    backgroundColor: '#CC7191 !important',
    margin: 'auto 16px !important',
    boxShadow: 'none !important',
    '&:hover': {
        backgroundColor: '#faf1f4 !important',
        color: '#CC7191 !important'
    },
}));

export default function UploadFileCard({ onClose, data }) {

    const classes = useStyles();
    const [file, setFile] = useState(null);

    const onAddImage = () => {
        if( file ) {
            // return callApiWithToken(null, 'post', 'https://1ok7ig1uzj.execute-api.us-east-1.amazonaws.com/dev/', {
            //     file: file
            // })
            // .then(res => {
            // })
            // .catch(error => {
            //     return error
            // });
        }
    }

    // const [myFiles, setMyFiles] = useState(null);

    // const onDrop = useCallback(
    //     (acceptedFiles) => {
    //         setMyFiles([...acceptedFiles]);
    //     },
    //     [myFiles]
    // );

    // const { getRootProps, getInputProps } = useDropzone({
    //     onDrop
    // });

    // const files = myFiles?.map((file) => (
    //     <>
    //     <img data-dz-thumbnail={file.path} />
    //         <li key={file.path}>
    //             {file.path} - {(file.size / 1024)?.toFixed(2)} kbs{' '}
    //         </li>
    //     </>
    // ));

    return (
        <Box>
            <Box >
                {/* <div
                    className={classes.fileUploadCard}
                    {...getRootProps({
                        style: {
                            width: '100%',
                            borderWidth: '3px',
                            borderColor: '#e3e4e5',
                            borderStyle: 'dashed',
                            borderRadius: '5px',
                            padding: '10px'
                        }
                    })}
                >
                    <input {...getInputProps()} />
                    {myFiles ? files : (<>
                        <IconButton className={classes.fileUploadBtn}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.3">
                                    <path d="M19.6001 11.4L1.6001 11.4" stroke="#070D23" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10.6001 20.4L10.6001 2.40002" stroke="#070D23" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                            </svg>
                        </IconButton>
                        <Typography variant="h6" component="h6" style={{ marginTop: '1rem' }}>
                            Add Image
                        </Typography>
                    </>)}
                </div> */}
                <BasicDropzone setFile={setFile}/>
            </Box>

            <Box>
                <TextareaAutosize
                    minRows={4}
                    aria-label="maximum height"
                    placeholder="Add Note"
                    className={classes.addNoteInput}
                    defaultValue={data && data.description}
                />
            </Box>
            <Box className={classes.btnGroup}>
                <CancelButton variant="contained" size="large" onClick={onClose}>Cancel</CancelButton>
                <AddImageButton variant="contained" size="large" onClick={onAddImage} disabled={!file}>Add Image</AddImageButton>
            </Box>
        </Box>
    );
}
