import React from 'react';
import {
    Grid,
    Typography,
} from "@material-ui/core";

export const GridField = ({title, col = 6, isHtml = false, children}) => {

    return (
        <Grid item lg={col} md={col} sm={12} xs={12}>
            {title &&
                <Typography>
                    <b>{title}</b>
                </Typography>
            }
            <Typography>
                {isHtml ? <div dangerouslySetInnerHTML={{ __html: children }} /> : children}
            </Typography>
        </Grid>
    )
}