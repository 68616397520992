import React, { useState } from "react";
import { Grid, Typography, IconButton, Card, CardContent, DialogContent, Dialog } from "@material-ui/core";
import ImageCard from "../components/Card";
import UploadFileCard from "../components/UploadFileCard";
import EditImageCard from "../components/EditImageCard";
import { makeStyles } from '@material-ui/styles'

const data = [
  { description: 'How to Choose a Reproductive Veterinarian', imgSrc: '/assets/image1.png' },
  { description: 'Avoiding Common Weaning Nursing Mistakes.', imgSrc: '/assets/image2.png' },
  { description: 'A Thorough Dog Checkup', imgSrc: '/assets/image3.png' },
  { description: 'How can you tell if your pets heart is pumping right?', imgSrc: '/assets/image4.png' },
  { description: 'Pretty Dog Getting A Checkup', imgSrc: '/assets/image5.png' },
]

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: '20px 15px !important',
    [theme.breakpoints.up('md')]: {
      padding: '20px 15px !important',
    }

  },
  fileUploadCard: {
    background: '#FFFFFF',
    // opacity: '0.1',
    border: '3px dashed #e3e4e5',
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none !important',
    borderRadius: '15px !important',
    [theme.breakpoints.up('xl')]: {
      borderRadius: '28px',
    },
  },

  fileUploadBtn: {
    background: '#F5F9FC !important',
    borderRadius: '80px !important',
    opacity: '100% !important'
  }
}));
const ManageImage = () => {

  const classes = useStyles();
  const [openUploadModal, setOpenUploadModal] = React.useState(false);
  const [openEditModal, setEditModal] = React.useState(false);


  // 
  const [editData, setEditData] = useState(null)


  const handleClose = () => setOpenUploadModal(false);
  const handleOpen = () => setOpenUploadModal(true);

  const handleEditClose = () => setEditModal(false);


  // 
  const handleItemSelected = (item) =>{
    setEditData(item);
    setEditModal(true);
  }
  const renderUploadImageDialog = (
    <Dialog fullWidth open={openUploadModal} onClose={handleClose}>
      <DialogContent className={classes.dialog}>
        <UploadFileCard onClose={handleClose} data={editData}/>
      </DialogContent>
    </Dialog>
  );

  const renderEditImageDialog = (
    <Dialog fullWidth open={openEditModal} onClose={handleEditClose} >
      <DialogContent>
        <EditImageCard data={editData} handleClose={handleEditClose} handleOpenUpload={handleOpen}/>
      </DialogContent>
    </Dialog>
  );

  return (<>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} key={'imageUpload'} >
        <Card className={classes.fileUploadCard}>
          <CardContent sx={{textAlign:'center'}}>
            <IconButton className={classes.fileUploadBtn} onClick={() => handleOpen()}>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.3">
                  <path d="M19.6001 11.4L1.6001 11.4" stroke="#070D23" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10.6001 20.4L10.6001 2.40002" stroke="#070D23" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>
            </IconButton>
            <Typography variant="h6" component="h6" style={{ marginTop: '1rem' }}>
              Add Image
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {data.map((item, index) => {
        return (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ my: 2 }}>
            <ImageCard descText={item.description} imageSrc={item.imgSrc} handelClick={() => handleItemSelected(item)} />
          </Grid>
        )
      })}
    </Grid>
    {renderUploadImageDialog}
    {renderEditImageDialog}
  </>
  );
};

export default ManageImage;
