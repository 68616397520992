import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Tooltip,
  Link,
} from "@material-ui/core";
import {
  tableHeaders,
} from "../config/constant";
import { useStyles } from "../theme/style";
import { GridField } from "../components/GridField";
import { ellipsis } from "utils/string";
import TableHeader from "components/TableHeader";
import Paging from "components/Paging";
import { downloadExcel } from "utils/download";
import { LoadingButton } from "@material-ui/lab";
import { Description } from "@material-ui/icons";
import { getSuggestedResources } from "services/resourceService";
import KitImg from "components/kitImg";
import { Box } from "@material-ui/system";
export default function ManageSuggestedResources() {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      setIsLoading(true);
      const res = await getSuggestedResources({ skip: curPage - 1, take: pageSize });
      if (res) {
        setComments(res.data);
        setTotal(res.total);
      } else {
        setComments([]);
        setTotal(0);
      }
      setIsLoading(false);
    })(); // eslint-disable-next-line
  }, []);

  const handleDownload = async () => {
    setDownloading(true);
    const res = await getSuggestedResources();
    if (res && res.total > 0) {
      downloadExcel('Suggested Resources', res.data);
    }
    setDownloading(false);
  }

  const handleClickDetails = (index) => {
    setSelectedIndex(index);
    setDialogOpen(true);
  };

  const onPageChange = (page) => {
    setCurPage(page);
    (async function () {
      setIsLoading(true);
      const res = await getSuggestedResources({ skip: (page - 1) * pageSize, take: pageSize });
      if (res) {
        setComments(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const onRowsPerPageChange = (newSize) => {
    setCurPage(1);
    setPageSize(newSize);
    (async function () {
      setIsLoading(true);
      const res = await getSuggestedResources({ skip: 0, take: newSize });
      if (res) {
        setComments(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader fields={tableHeaders.SuggestResource.fields} />
          <TableBody>
            {comments?.map(
              (item, index) => (
                item.Read !== true &&
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    className={classes.tableBody}
                    component="th"
                    scope="row"
                  >
                    {index + 1 + (curPage - 1) * pageSize}
                  </TableCell>
                  <TableCell
                    className={classes.tableBody}
                    component="th"
                    scope="row"
                  >
                    {item.ResourceType}
                  </TableCell>
                  <TableCell
                    className={classes.tableBody}
                    component="th"
                    scope="row"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <KitImg
                        src={item.SubmittedBy?.ProfileImage || "/assets/user.png"}
                        kitwidth={100}
                        alt="user"
                        className={classes.userImg}
                      />
                      {item.SubmittedBy?.DisplayName}
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {ellipsis(item.Description || "", 140)}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    <Link underline={"none"} href={item.Links}>{item.Links}</Link>
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {moment(item.SubmittedDate).format("LL")}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {item.IsAlreadyCreated ? "Exist" : "New"}
                  </TableCell>
                  <TableCell className={classes.btnGroup}>
                    <Tooltip title="Detail">
                      <IconButton
                        color="primary"
                        onClick={() => handleClickDetails(index)}
                      >
                        <Description />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <LoadingOverlay active={isLoading} spinner={true} text="Loading...">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <LoadingButton
          loading={downloading}
          onClick={handleDownload}
          variant="contained"
          color="success"
        >Download</LoadingButton>
        <Grid item>Total: {total}</Grid>
      </Grid>
      {renderTable()}
      <Paging
        onPageSizeChange={onRowsPerPageChange}
        onPageChange={onPageChange}
        total={total}
        curPage={curPage}
      />
      <Dialog
        onClose={() => setDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setDialogOpen(false)}
        >
          Suggested details
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <GridField title="Suggested By" col={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <KitImg
                  src={comments[selectedIndex]?.SubmittedBy?.ProfileImage || "/assets/user.png"}
                  kitwidth={100}
                  alt="user"
                  className={classes.userImg}
                />
                {comments[selectedIndex]?.SubmittedBy?.DisplayName}
              </Box></GridField>
          </Grid>
          <Grid container>
            <GridField title="Type" col={6}>{comments[selectedIndex]?.ResourceType}</GridField>
            <GridField title="Submission Date" col={6}>{moment(comments[selectedIndex]?.SubmittedDate).format("LL")}</GridField>
          </Grid>
          <Grid container>
            <GridField title="Description" col={12}>{comments[selectedIndex]?.Description}</GridField>
          </Grid>
          <Grid>
            <GridField title="Link" col={12}>
              <Link underline={"none"} href={comments[selectedIndex]?.Links}>{comments[selectedIndex]?.Links}</Link>
            </GridField>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setDialogOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingOverlay>
  );
}