import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Snackbar,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import {
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_ACTIVE,
  STATUS_NAMES,
  tableHeaders,
} from "../config/constant.js";
import TabPanel from "../components/TabPanel";
import { useStyles } from "../theme/style";
import { ApproveTagEmail } from "services/emailService";
import { getUser, updateUser } from "services/userService";
import KitImg from "components/kitImg";
import { getTags, updateTagStatus } from "services/tagService";
import Alert from "components/Alert";
import { a11yProps } from "utils/mui";
import { LoadingButton } from "@material-ui/lab";
import { downloadExcel } from "utils/download.js";
import TableHeader from "components/TableHeader.js";
import { Close, Done } from "@material-ui/icons";

export default function ManageSuggestedTags() {
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [curStatus, setCurStatus] = useState(STATUS_ACTIVE);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const res = await getTags();
      if (res && res.data?.length) {
        setTags(res.data);
        let tmpData = [];
        tmpData[STATUS_PENDING] = res.data.filter((item => item.Status === STATUS_PENDING));
        tmpData[STATUS_ACTIVE] = res.data.filter((item => item.Status === STATUS_ACTIVE));
        tmpData[STATUS_REJECTED] = res.data.filter((item => item.Status === STATUS_REJECTED));
      }
      setIsLoading(false);
    })();
  }, []);

  const handleDownload = async () => {
    setDownloading(true);
    const res = await getTags();
    if (res && res.total > 0) {
      downloadExcel(`${STATUS_NAMES[curStatus]} Tags`, res.data.filter((item => item.Status === curStatus)));
    }
    setDownloading(false);
  }

  const handleClickAction = async (index, action) => {
    const tag = tags[index];
    setIsLoading(true);
    const res = await updateTagStatus(tag?.Id, action);
    if (res) {
      setTags(origin => origin.map(tag => (tag.Id === tags[index].Id) ? { ...tag, Status: res.data?.Status } : tag));
      if (action === STATUS_ACTIVE) {
        setMessage("Tag approved successfully!");
        let isAdded = false;
        const res = await getUser(tag?.SuggestedBy?.Id);
        if (res) {
          if (res.BreederTags?.length < 4) {
            const updateRes = await updateUser({ ...res, BreederTags: [...res.BreederTags, { BreederId: tags[index]?.SuggestedBy?.Id, Name: tag.TagName }] });
            if (!updateRes?.error) { isAdded = true; }
          }
        }
        await ApproveTagEmail(tag.SuggestedBy?.Email, tag.TagName, tag.SuggestedBy?.UserName || tag.SuggestedBy?.DisplayName, isAdded);
      }
      if (action === STATUS_REJECTED) {
        setMessage("Tag rejected successfully!");
      }
    }
    setIsLoading(false);
  };

  const renderTable = (status) => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader fields={tableHeaders.SuggestTag.fields} />
          <TableBody>
            {tags.map(
              (tag, index) =>
                tag.Status === status && (
                  <TableRow
                    key={tag.Id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      className={classes.tableBody}
                      component="th"
                      scope="row"
                    >
                      {tag.Id}
                    </TableCell>
                    <TableCell>
                      {tag.TagName}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <KitImg
                          src={tag.SuggestedBy?.ProfileImage || "/assets/user.png"}
                          kitwidth={100}
                          className={classes.userImg}
                        />{"  "}
                        {tag.SuggestedBy?.DisplayName || tag.SuggestedBy?.Name}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {moment(tag?.CreatedDat).format("LL")}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {moment(tag?.UpdatedDate).format("LL")}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {tag?.Status
                        ? STATUS_NAMES[tag?.Status]
                        : STATUS_NAMES[STATUS_PENDING]}
                    </TableCell>
                    <TableCell className={classes.tableBody} align="left">
                      {tag.Status !== STATUS_ACTIVE &&
                        <Tooltip title="Approve">
                          <IconButton
                            onClick={() => handleClickAction(index, STATUS_ACTIVE)}
                            color="success"
                          >
                            <Done />
                          </IconButton>
                        </Tooltip>
                      }
                      {tag.Status !== STATUS_REJECTED &&
                        <Tooltip title="Reject">
                        <IconButton
                          onClick={() => handleClickAction(index, STATUS_REJECTED)}
                          color="error"
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                      }
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>

        <Snackbar
          open={!!message}
          autoHideDuration={6000}
          onClose={() => setMessage("")}
        >
          <Alert
            onClose={() => setMessage("")}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </TableContainer>
    );
  };

  return (
    <>
      <LoadingOverlay active={isLoading} spinner={true} text="Loading...">
        <LoadingButton
          loading={downloading}
          onClick={handleDownload}
          variant="contained"
          color="success"
        >Download</LoadingButton>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(e, v) => setValue(v)}
            aria-label="basic tabs example"
          >
            <Tab label="Active tags" {...a11yProps(0)} onClick={() => setCurStatus(STATUS_ACTIVE)} />
            <Tab label="Pending tags" {...a11yProps(1)} onClick={() => setCurStatus(STATUS_PENDING)} />
            <Tab label="Rejected tags" {...a11yProps(2)} onClick={() => setCurStatus(STATUS_REJECTED)} />
          </Tabs>
        </Box>
        <TabPanel>
          {renderTable(curStatus)}
        </TabPanel>
      </LoadingOverlay>
    </>
  );
}