import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Snackbar,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { tableHeaders } from "../config/constant";
import { useStyles } from "../theme/style";
import Alert from "components/Alert";
import { getSubscribes, unsubscribe } from "services/subscribeService";
import TableHeader from "components/TableHeader";
import Paging from "components/Paging";
import { LoadingButton } from "@material-ui/lab";
import { downloadExcel } from "utils/download";
import { Delete } from "@material-ui/icons";

export default function ManageSubscribes() {
  const classes = useStyles();
  const [subscribe, setSubscribe] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(true);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [curPage, setCurPage] = useState(1)
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const res = await getSubscribes({
        skip: curPage - 1,
        take: pageSize
      });
      if (res && res.data?.length) {
        setSubscribe(res.data);
        setTotal(res.total);
      } else {
        setSubscribe([]);
        setTotal(0);
      }
      setIsLoading(false);
    })(); // eslint-disable-next-line
  }, []);

  const handleClickAction = async (index) => {
    setIsLoading(true);
    const res = await unsubscribe(subscribe[index]?.Id);
    if (res) {
      setSubscribe(origin => { origin.splice(index, 1); return origin; })
      setUpdateFlag(!updateFlag);
      setMessage("Email unsubscribed successfully!");
    }
    setIsLoading(false);
  };

  const handleDownload = async () => {
    setDownloading(true);
    const res = await getSubscribes({});
    if (res && res.total > 0) {
      downloadExcel('subscribes', res.data);
    }
    setDownloading(false);
  }

  const onRowsPerPageChange = (newSize) => {
    setCurPage(1);
    setPageSize(newSize);
    (async function () {
      setIsLoading(true);
      const res = await getSubscribes({ skip: 0, take: newSize });
      if (res) {
        setSubscribe(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const onPageChange = (page) => {
    setCurPage(page);
    (async function () {
      setIsLoading(true);
      const res = await getSubscribes({ skip: (page - 1) * pageSize, take: pageSize });
      if (res) {
        setSubscribe(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  return (
    <LoadingOverlay active={isLoading} spinner={true} text="Loading...">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <LoadingButton
          loading={downloading}
          onClick={handleDownload}
          variant="contained"
          color="success"
        >Download</LoadingButton>
        <Grid item>Total: {total}</Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader fields={tableHeaders.subscribe.fields} />
          <TableBody>
            {subscribe.map(
              (item, index) =>
                item && (
                  <TableRow
                    key={item.Id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className={classes.tableBody} component="th" scope="row">{index + 1 + (curPage - 1) * pageSize}</TableCell>
                    <TableCell className={classes.tableBody} align="left">{item?.EmailAddress}</TableCell>
                    <TableCell className={classes.tableBody} align="left">{moment(item?.CreatedDate).format("LL")}</TableCell>
                    <TableCell>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => handleClickAction(index)}
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>

        <Snackbar
          open={false}
          autoHideDuration={6000}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </TableContainer>
      <Paging
        onPageSizeChange={onRowsPerPageChange}
        onPageChange={onPageChange}
        total={total}
        curPage={curPage}
      />
    </LoadingOverlay>
  );
}
