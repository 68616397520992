import * as React from 'react';
import clsx from 'clsx';
// import { styled } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
// import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
import jwtAuthService from "../../services/jwtAuthService";
import { Hidden } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: theme.palette.common.white,
//     '&:hover': {
//         backgroundColor: theme.palette.common.white,
//     },
//     marginRight: theme.spacing(2),
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('xs')]: {
//         marginLeft: theme.spacing(3),
//         width: 'auto',
//     },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 1),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     zIndex: '1',
//     color: '#AFB3B6',
//     [theme.breakpoints.down('sm')]: {
//         left: '10px'
//     },
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: '#AFB3B6',
//     '& .MuiInputBase-input': {
//         padding: theme.spacing(1, 1, 1, 4),
//         paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//         transition: theme.transitions.create('width'),
//         width: '100%',
//         backgroundColor: '#F5F9FC',
//         borderRadius: '34px',
//         [theme.breakpoints.up('md')]: {
//             width: '25ch',
//         },
//     },
// }));

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const useStyles = makeStyles((theme) => ({
    appbar: {
        boxShadow: 'none !important',
        background: '#fff !important',
        backdropFilter: 'blur(8px)',
        color: '#070D23 !important',
        borderBottom: '3px solid #F7F7F7',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: DRAWER_WIDTH,
            paddingRight: '50px',
        }
    },
    toolbar: {
        minHeight: APPBAR_MOBILE,
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 5)
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: APPBAR_DESKTOP,
            maxHeight: APPBAR_MOBILE,
        }
    },
    notificationsIcon: {
        backgroundColor: '#F1F8FD !important',
        maxHeight: '50px !important',
        '&:hover': {
            backgroundColor: '#F1F8FD !important',
        }
    },
    profile: {
        borderRadius: '96px !important',
        '&:hover': {
            backgroundColor: 'transparent !important',
        }
    },
    profileImg: {
        width: '64px',
        height: '64px',
    }
}));

export default function TopBar({ onOpenNav }) {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const { pathname } = useLocation();
    const MenuLinks = [
        { link: 'Users', pathname: '/' },
        { link: 'Subscribes', pathname: '/subscribes' },
        { link: 'Articles', pathname: '/articles' },
        { link: 'Suggest Resources', pathname: '/suggested-resources'},
        { link: 'Pawfficehours', pathname: '/pawffices' },
        { link: 'Referral Codes', pathname: '/referral-codes' },
        { link: 'Community Groups', pathname: '/community-groups'},
        { link: 'Suggest Tags', pathname: '/suggested-tags'},
        { link: 'Comments', pathname: '/comments'},
        { link: 'Reports', pathname: '/reports'},
    ]

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleLogout = (event) => {
        jwtAuthService.logout();
        window.location.reload();
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem sx={{justifyContent:'center', padding:'0'}}>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                    className={classes.notificationsIcon}
                >
                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.60255 14.749V14.5208C1.60255 13.8362 1.83077 13.0374 2.28718 12.4669C2.97179 11.7823 3.42821 10.8695 3.65642 9.84259C3.65642 9.04387 3.65643 8.35926 3.77053 7.56054C4.11284 3.90926 7.76409 1.39905 11.3013 1.39905H11.4154C14.9525 1.39905 18.6038 3.90926 18.9461 7.56054C19.0602 8.35926 18.9461 9.04387 18.9461 9.84259C19.1743 10.8695 19.6308 11.7823 20.3154 12.4669C20.7718 13.0374 21 13.8362 21 14.5208V14.749C21 15.7759 20.6577 16.6887 20.0872 17.4875C19.1743 18.4003 18.0333 18.9708 16.7782 19.0849C13.1269 19.4272 9.47564 19.4272 5.82436 19.0849C4.56923 18.9708 3.42821 18.4003 2.51539 17.4875C1.83078 16.6887 1.48845 15.7759 1.60255 14.749Z" stroke="#142669" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.44867 22.8503C9.01918 23.535 9.8179 23.9913 10.7307 24.1054C11.6435 24.2195 12.5564 23.9914 13.3551 23.4209C13.5833 23.3068 13.8115 23.0785 13.9256 22.8503" stroke="#142669" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </IconButton>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen} sx={{justifyContent:'center', padding:'0'}}>
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    className={classes.profile}
                >
                    <img className={classes.profileImg} src="/assets/user.png" alt="profile" />
                </IconButton>
            </MenuItem>
        </Menu>
    );

    return (
        <Box >
            <AppBar className={clsx(classes.appbar)}>
                <Toolbar>
                    <Hidden lgUp>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 }}
                            onClick={onOpenNav}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        {MenuLinks.filter(item => item.pathname === pathname)[0].link}
                    </Typography>
                    {/* <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search> */}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                            className={classes.notificationsIcon}
                        >
                            <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.60255 14.749V14.5208C1.60255 13.8362 1.83077 13.0374 2.28718 12.4669C2.97179 11.7823 3.42821 10.8695 3.65642 9.84259C3.65642 9.04387 3.65643 8.35926 3.77053 7.56054C4.11284 3.90926 7.76409 1.39905 11.3013 1.39905H11.4154C14.9525 1.39905 18.6038 3.90926 18.9461 7.56054C19.0602 8.35926 18.9461 9.04387 18.9461 9.84259C19.1743 10.8695 19.6308 11.7823 20.3154 12.4669C20.7718 13.0374 21 13.8362 21 14.5208V14.749C21 15.7759 20.6577 16.6887 20.0872 17.4875C19.1743 18.4003 18.0333 18.9708 16.7782 19.0849C13.1269 19.4272 9.47564 19.4272 5.82436 19.0849C4.56923 18.9708 3.42821 18.4003 2.51539 17.4875C1.83078 16.6887 1.48845 15.7759 1.60255 14.749Z" stroke="#142669" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.44867 22.8503C9.01918 23.535 9.8179 23.9913 10.7307 24.1054C11.6435 24.2195 12.5564 23.9914 13.3551 23.4209C13.5833 23.3068 13.8115 23.0785 13.9256 22.8503" stroke="#142669" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            className={classes.profile}
                        >
                            <img className={classes.profileImg} src="/assets/user.png" alt="profile" />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </Box>
    );
}
