import * as React from 'react';
import { makeStyles, styled } from '@material-ui/styles';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { Box, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    card: {
        background: '#FFFFFF',
        boxShadow: '0px 14px 50px rgba(20, 38, 105, 0.03)',
        borderRadius: '28px',
        position: 'absolute'
    },
    btnGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '12px',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            '& button': {
                marginTop: '10px !important',
                width: '100%'
            },
        }
    },
    changeButton: {
        backgroundColor: 'rgba(105, 114, 155, 0.9)',
        width: '111px',
        height: '38px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: '15px',
        top: '5px',
        borderRadius: '5px',
        cursor: 'pointer'
    }
}));
const CancelButton = styled(Button)(({ theme }) => ({
    color: '#CC7191 !important',
    backgroundColor: '#faf1f4 !important',
    margin: 'auto 16px !important',
    boxShadow: 'none !important',
    '&:hover': {
        backgroundColor: '#CC7191 !important',
        color: '#faf1f4 !important'
    },
}));

const UpdateButton = styled(Button)(({ theme }) => ({
    color: '#faf1f4 !important',
    backgroundColor: '#CC7191 !important',
    margin: 'auto 16px !important',
    boxShadow: 'none !important',
    '&:hover': {
        backgroundColor: '#faf1f4 !important',
        color: '#CC7191 !important'
    },
}));

const DeleteButton = styled(Button)(({ theme }) => ({
    color: '#CC7191 !important',
    backgroundColor: '#fff !important',
    margin: 'auto 16px !important',
    boxShadow: 'none !important',
    border: '1px solid #CC7191 !important',
    boxSizing: 'borderBox !important',
    '&:hover': {
        backgroundColor: '#CC7191 !important',
        color: '#faf1f4 !important'
    },
}));

export default function EditImageCard({ handleClose, data, handleOpenUpload }) {

    const classes = useStyles();

    return (
        <Box style={{ position: 'relative' }}>
            <Box className={classes.changeButton} onClick={handleOpenUpload}>
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.63751 1.72795C10.3375 0.927948 11.5375 0.72796 12.3375 1.42796L12.4375 1.528L13.8375 2.62797C14.7375 3.22797 15.0375 4.42798 14.4375 5.32798L14.3375 5.42796L6.0375 16.028C5.7375 16.428 5.33751 16.628 4.93751 16.628H1.7375L1.0375 13.528C0.9375 13.128 1.0375 12.6279 1.3375 12.2279L9.63751 1.72795Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.1377 3.62799L12.9377 7.42798" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <Typography variant="body2" style={{ marginLeft: '10px' }}>
                    Change
                </Typography>
            </Box>
            <CardMedia
                component="img"
                height="auto"
                image={data.imgSrc}
                alt={"imageSrc"}
                style={{ objectFit: 'cover', borderRadius: '10px' }}
            />
            <CardContent sx={{ px: 0 }}>
                <Typography variant="h6" sx={{ padding: '0' }}>
                    {data.description}
                </Typography>
            </CardContent>
            <Box className={classes.btnGroup}>
                <DeleteButton>Delete</DeleteButton>
                <UpdateButton>Update</UpdateButton>
                <CancelButton onClick={handleClose}>Cancel</CancelButton>
            </Box>
        </Box>
    );
}
