import { convertQuery } from "utils/string";
import { getHeader, getInstance } from "./basicService";

export const getUsers = async function (status, query) {
  let res = null;
  await getInstance()
    .get(`${process.env.REACT_APP_API_BASE_URL}/api/admin/breeder/status/${status}${convertQuery(query)}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Brreders get error:", err);
    })
  return res;
}

export const getUser = async function (id) {
  let res = null;
  await getInstance()
    .get(`${process.env.REACT_APP_API_BASE_URL}/api/breeder/${id}`)
    .then(({ data }) => {
      res = data;
    })
    .catch((err) => {
      console.error("Breeder get error:", err);
    });
  return res;
};

export const updateUser = async function (data) {
  let res = null;
  await getInstance()
    .put(`${process.env.REACT_APP_API_BASE_URL}/api/breeder`, data, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch((err) => {
      console.error("User update error:", err);
    });
  return res;
};

export const updateUserStatus = async function (id, value) {
  let res = null;
  await getInstance()
    .put(`${process.env.REACT_APP_API_BASE_URL}/api/admin/breeder/status`, {
      BreederId: id,
      Status: value
    }, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch((err) => {
      console.error("User status update error:", err);
    });
  return res;
};

export const showHomeUser = async function (id) {
  let res = null;
  await getInstance()
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/admin/breeder/${id}/show-on-home`, {}, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("User set home error:", err);
    })
  return res;
}

export const restoreUser = async function (id) {
  let res = null;
  await getInstance()
    .put(`${process.env.REACT_APP_API_BASE_URL}/api/admin/breeder/restore-status/${id}`, {}, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch((err) => {
      console.error("User restore error:", err);
    });
  return res;
};

export const createEmailToken = async function (token) {
  let res = null;
  await getInstance()
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/EmailToken`, {
      token: token
    }, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch((err) => {
      console.error("Create token error:", err);
    });
  return res;
}
