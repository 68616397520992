import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Link,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Icon,
} from "@material-ui/core";
import {
  STATUS_DELETED,
  STATUS_NAMES,
  STATUS_READ,
  STATUS_UNREAD,
  tableHeaders,
} from "../config/constant";
import { useStyles } from "../theme/style";
import { GridField } from "../components/GridField";
import { deleteComment, getCommentDeletes, getComments, hideComment, markReadComment, pinComment } from "services/commentService";
import { ellipsis, htmlToText } from "utils/string";
import TableHeader from "components/TableHeader";
import Paging from "components/Paging";
import { downloadExcel } from "utils/download";
import { LoadingButton } from "@material-ui/lab";
import { Delete, Description, Done, Visibility } from "@material-ui/icons";
import { a11yProps } from "utils/mui";
import { Box } from "@material-ui/system";
import TabPanel from "components/TabPanel";
import { DeletedPostEmail } from "services/emailService";
import PinIcon from "../assets/pin.png";
import UnPinIcon from "../assets/unpin.png";

export default function ManageComments() {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [downloading, setDownloading] = useState(false);
  const [value, setValue] = useState(0);
  const [curStatus, setCurStatus] = useState(STATUS_UNREAD);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      setIsLoading(true);
      const res = await (curStatus === STATUS_DELETED
        ? getCommentDeletes({ skip: curPage - 1, take: pageSize })
        : getComments({ skip: curPage - 1, take: pageSize, read: curStatus === STATUS_READ }));
      console.log(res);
      if (res) {
        setComments(res.data);
        setTotal(res.total);
      } else {
        setComments([]);
        setTotal(0);
      }
      setIsLoading(false);
    })(); // eslint-disable-next-line
  }, [curStatus]);

  const handleDownload = async () => {
    setDownloading(true);
    const res = await ((curStatus === STATUS_DELETED)
      ? getCommentDeletes()
      : getComments({ read: curStatus === STATUS_READ }));
    if (res && res.total > 0) {
      downloadExcel(`${STATUS_NAMES[curStatus]} Comments`, res.data);
    }
    setDownloading(false);
  }

  const handleClickDetails = (index) => {
    setSelectedIndex(index);
    setDialogOpen(true);
  };

  const handleMark = async (index) => {
    const selectedComment = comments[index]
    const res = await markReadComment(selectedComment.TypeId, selectedComment.Id);
    if (res) {
      setDialogOpen(false);
      setComments(origin => origin.map((c, idx) => idx === index ? { ...c, Read: true } : c));
    }
  }

  const handleDelete = async (index) => {
    const selectedComment = comments[index]
    const res = await deleteComment(selectedComment.TypeId, selectedComment.Id);
    if (res) {
      await DeletedPostEmail(selectedComment.CreatedByEmail, selectedComment.Author, selectedComment.Location)
      setComments(origin => origin.map((c, idx) => idx === index ? { ...c, Read: true } : c));
    }
  }

  const handleHide = async (index) => {
    const selectedComment = comments[index]
    const res = await hideComment(selectedComment.TypeId, selectedComment.Id);
    if (res) {
      setComments(origin => origin.map((c, idx) => idx === index ? { ...c, IsHidden: !c.IsHidden } : c));
    }
  }

  const handlePin = async (index) => {
    const selectedComment = comments[index]
    const res = await pinComment(selectedComment.Id);
    if (res) {
      setComments(origin => origin.map((c, idx) => idx === index ? { ...c, IsPinned: !c.IsPinned } : c));
    }
  }

  const onPageChange = (page) => {
    setCurPage(page);
    (async function () {
      setIsLoading(true);
      const res = await (curStatus === STATUS_DELETED
        ? getCommentDeletes({ skip: (page - 1) * pageSize, take: pageSize })
        : getComments({ skip: (page - 1) * pageSize, take: pageSize, read: curStatus === STATUS_READ }));
      if (res) {
        setComments(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const onRowsPerPageChange = (newSize) => {
    setCurPage(1);
    setPageSize(newSize);
    (async function () {
      setIsLoading(true);
      const res = await (curStatus === STATUS_DELETED
        ? getCommentDeletes({ skip: 0, take: newSize })
        : getComments({ skip: 0, take: newSize, read: curStatus === STATUS_READ }));
      if (res) {
        setComments(res.data);
        setTotal(res.total);
      }
      setIsLoading(false);
    })();
  }

  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader fields={tableHeaders.comment.fields} />
          <TableBody>
            {comments?.map(
              (item, index) => (
                item.Read !== true &&
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    className={classes.tableBody}
                    component="th"
                    scope="row"
                  >
                    {index + 1 + (curPage - 1) * pageSize}
                  </TableCell>
                  <TableCell
                    className={classes.tableBody}
                    component="th"
                    scope="row"
                  >
                    {item.Type}
                  </TableCell>
                  <TableCell
                    className={classes.tableBody}
                    component="th"
                    scope="row"
                  >
                    {item.Author}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {item.Location}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {ellipsis(htmlToText(item.Content), 140)}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {item.TypeId < 3
                      ? <Link underline={"none"} href={`${process.env.REACT_APP_FRONT_BASE_URL}/discuss/convos/${item.LocationId}`} target="_blank">View Community</Link>
                      : <Link underline={"none"} href={`${process.env.REACT_APP_FRONT_BASE_URL}/article/${item.LocationId}`} target="_blank">View Article</Link>}
                  </TableCell>
                  <TableCell className={classes.tableBody} align="left">
                    {moment(item.CreatedDate).format("LL")}
                  </TableCell>
                  <TableCell className={classes.btnGroup}>
                    <Tooltip title="Detail">
                      <IconButton
                        color="primary"
                        onClick={() => handleClickDetails(index)}
                      >
                        <Description />
                      </IconButton>
                    </Tooltip>
                    {curStatus !== STATUS_DELETED &&
                      <>
                        {
                          curStatus === STATUS_UNREAD &&
                          <Tooltip title="Mark as read">
                            <IconButton
                              onClick={() => handleMark(index)}
                              color="success"
                            >
                              <Done />
                            </IconButton>
                          </Tooltip>
                        }
                        <Tooltip title={item.IsHidden ? "Unhide" : "Hide"}>
                          <IconButton
                            onClick={() => handleHide(index)}
                            color={item.IsHidden ? undefined : "warning"}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                        {comments[index]?.TypeId < 3 &&
                          <Tooltip title={item.IsPinned ? "Unpin" : "Pin"}>
                            <IconButton
                              onClick={() => handlePin(index)}
                            >
                              <Icon><img src={item.IsPinned ? PinIcon : UnPinIcon} alt="" /></Icon>
                            </IconButton>
                          </Tooltip>}
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => handleDelete(index)}
                            color="error"
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <LoadingOverlay active={isLoading} spinner={true} text="Loading...">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <LoadingButton
          loading={downloading}
          onClick={handleDownload}
          variant="contained"
          color="success"
        >Download</LoadingButton>
        <Grid item>Total: {total}</Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={(e, v) => setValue(v)} aria-label="basic tabs example">
          <Tab label="Unread Comments" {...a11yProps(STATUS_UNREAD)} onClick={() => setCurStatus(STATUS_UNREAD)} />
          <Tab label="Read Comments" {...a11yProps(STATUS_READ)} onClick={() => setCurStatus(STATUS_READ)} />
          <Tab label="Deleted Comments" {...a11yProps(STATUS_DELETED)} onClick={() => setCurStatus(STATUS_DELETED)} />
        </Tabs>
      </Box>
      <TabPanel>
        {renderTable()}
        <Paging
          onPageSizeChange={onRowsPerPageChange}
          onPageChange={onPageChange}
          total={total}
          curPage={curPage}
        />
      </TabPanel>
      <Dialog
        onClose={() => setDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setDialogOpen(false)}
        >
          Comment details (ID: {comments[selectedIndex]?.Id})
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <GridField title="Title" col={6}>{comments[selectedIndex]?.Location}</GridField>
            <GridField title="Author" col={6}>{comments[selectedIndex]?.Author}</GridField>
          </Grid>
          <Grid container>
            <GridField title="Posted to" col={6}>{comments[selectedIndex]?.PostedTo}</GridField>
          </Grid>
          <Grid>
            <GridField title="Content" isHtml={true} col={12}>{comments[selectedIndex]?.Content}</GridField>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setDialogOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingOverlay>
  );
}