import { uploadFile } from "react-s3";

const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const AWSUploadFile = async (file, dir) => {
  let res;
  await uploadFile(file, { ...config, dirName: dir })
    .then((data) => (res = data))
    .catch((err) => {
      res = {};
      console.error("File upload error:", err);
    });
  return res;
};

export default AWSUploadFile;
