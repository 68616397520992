import { convertQuery } from "utils/string";
import { getHeader, getInstance } from "./basicService";

export const getSubscribes = async function (query) {
  let res = null;
  await getInstance()
    .get(`${process.env.REACT_APP_API_BASE_URL}/api/Breeder/subscribe${convertQuery(query)}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Subscribes get error:", err);
    })
  return res;
}

export const unsubscribe = async function (id) {
  let res = null;
  await getInstance()
    .put(`${process.env.REACT_APP_API_BASE_URL}/api/Breeder/unsubscribe/${id}`, getHeader())
    .then(({ data }) => {
      res = data;
    })
    .catch(err => {
      console.error("Unubscribe error:", err);
    })
  return res;
}