import axios from "axios";
import localStorageService from "./localStorageService";
import { callApiWithToken } from "./apiCallService";
import { init } from "./basicService";

class JwtAuthService {
  user = {
    userId: "1",
    role: 'ADMIN',
    displayName: "Admin manager",
    email: "admin@telltail.com",
    age: 25,
    access_token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh"
  }

  loginWithEmailAndPassword = (email, password) => {
    return callApiWithToken(null, 'post', process.env.REACT_APP_API_BASE_URL + '/api/auth/Login', {
        'Username': email,
        'Password': password
    })
    .then(res => {
      this.setSession(res.data.access_token);
      return res.data;
    })
    .catch(error => {
        return error
    });
  };

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then(data => {
      if(localStorage.getItem('jwt_token')) {
        return data;
      }
      else {
          return null;
      }
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  }

  // Set token to all http request header, so you don't need to attach everytime
  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      init(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Save user to localstorage
  setUser = (user) => {    
    localStorageService.setItem("auth_user", user);
  }
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("auth_user");
  }
}

export default new JwtAuthService();
